/** @format */

import { CardShortened } from "@roadflex/types";
import { Dialog } from "primereact/dialog";
import { Button, ButtonSize, ButtonType, ButtonVariant } from "../../buttons";
import { Toast } from "../../toast-message/toast";

type Props = {
  title: string;
  body: JSX.Element;
  show: boolean;
  setShow: (value: { open: boolean; row: CardShortened | null }) => void;
  onSubmit: (cardId: string) => void;
  cardId: string;
  loading: boolean;
};

export function RemovePolicySettingModal({
  title,
  body,
  show,
  setShow,
  onSubmit,
  cardId,
  loading,
}: Props) {
  const footer = (
    <div className="flex flex-row justify-end">
      <Button
        type={ButtonType.Button}
        variant={ButtonVariant.Red}
        size={ButtonSize.AppSize}
        //disabled={isSubmitting}
        className="w-full"
        onClick={() => setShow({ open: false, row: null })}
      >
        Cancel
      </Button>
      <Button
        variant={ButtonVariant.Black}
        size={ButtonSize.AppSize}
        disabled={loading}
        loading={loading}
        className="w-full ml-3"
        onClick={() => {
          if (cardId) {
            onSubmit(cardId);
          } else {
            Toast({ type: "error", message: "Select a card id" });
          }
        }}
      >
        Save
      </Button>
    </div>
  );

  return (
    <Dialog
      header={<div className="text-base">{title}</div>}
      style={{ minWidth: "60vw", maxWidth: "80vw", fontFamily: "Inter" }}
      visible={show}
      onHide={() => setShow({ open: false, row: null })}
      closeOnEscape={true}
      closable={false}
      footer={footer}
    >
      <div className="mt-2">{body}</div>
    </Dialog>
  );
}

export default RemovePolicySettingModal;
