import { dollarFormatter } from "@roadflex/constants";
import { TechnicalAnalysisDataDriverVehicleType } from "@roadflex/types";

type DriversFiltersType = {
  assignments: string[];
  spend: string[];
  discount: string[];
  fuel: string[];
};

export const rowExpansionTemplateDrivers = (
  rowData: TechnicalAnalysisDataDriverVehicleType,
  driversFilters: DriversFiltersType,
) => {
  return (
    <div className="max-w-sm p-4 mx-auto text-sm bg-white border border-gray-300 rounded-md shadow-lg">
      {/* 1) Assignments */}
      {(driversFilters.assignments.includes("card") ||
        driversFilters.assignments.includes("vehicle")) && (
        <section>
          <h5 className="mb-1 font-semibold">Assignments</h5>
          {driversFilters.assignments.includes("card") && (
            <div className="mb-1">
              <strong>Card:</strong> {rowData.cardNumber?.[0] || "-"}
            </div>
          )}
          {driversFilters.assignments.includes("vehicle") && (
            <div className="mb-1">
              <strong>Vehicle:</strong> {rowData.vehicleName?.[0] || "-"}
            </div>
          )}
        </section>
      )}

      {/* 2) Fuel */}
      {driversFilters.fuel.includes("fuel") && (
        <section>
          <h5 className="mb-1 font-semibold">Fuel (gal)</h5>
          <div>
            {Math.round(rowData.fuelQuantity * 100) / 100 /* to 2 decimals */}
          </div>
        </section>
      )}

      {/* 3) Spend */}
      {driversFilters.spend.length > 0 && (
        <section>
          <h5 className="mb-1 font-semibold">Spend</h5>
          <ul className="space-y-1 list-disc list-inside">
            {driversFilters.spend.map((s) => {
              switch (s) {
                case "spend30Days":
                  return (
                    <li key={s}>
                      Past 30 Days: {dollarFormatter(rowData.spend30Days / 100)}
                    </li>
                  );
                case "spend60Days":
                  return (
                    <li key={s}>
                      Past 60 Days: {dollarFormatter(rowData.spend60Days / 100)}
                    </li>
                  );
                case "spend90Days":
                  return (
                    <li key={s}>
                      Past 90 Days: {dollarFormatter(rowData.spend90Days / 100)}
                    </li>
                  );
                default:
                  return null;
              }
            })}
          </ul>
        </section>
      )}

      {/* 4) Discount */}
      {driversFilters.discount.length > 0 && (
        <section>
          <h5 className="mb-1 font-semibold">Discount</h5>
          <ul className="space-y-1 list-disc list-inside">
            {driversFilters.discount.map((d) => {
              switch (d) {
                case "discount30Days":
                  return (
                    <li key={d}>
                      Past 30 Days:{" "}
                      {dollarFormatter(rowData.discount30Days / 100)}
                    </li>
                  );
                case "discount60Days":
                  return (
                    <li key={d}>
                      Past 60 Days:{" "}
                      {dollarFormatter(rowData.discount60Days / 100)}
                    </li>
                  );
                case "discount90Days":
                  return (
                    <li key={d}>
                      Past 90 Days:{" "}
                      {dollarFormatter(rowData.discount90Days / 100)}
                    </li>
                  );
                default:
                  return null;
              }
            })}
          </ul>
        </section>
      )}
    </div>
  );
};
