import { useMutation } from "@apollo/client";
import {
  APPLY_CARD_POLICY,
  DELETE_CARD_POLICY,
  READ_APP_CARD_DATA,
  READ_CARD_POLICY,
  REMOVE_CARD_POLICY,
} from "@roadflex/graphql";
import { ApplyCardPolicy } from "@roadflex/types";
import { useFormik } from "formik";
import { useCallback } from "react";

type useApplyPolicyToCardsProps = {
  initialValues: ApplyCardPolicy;
  onSubmit: (
    val: {
      code: string;
      message: string;
    } | null,
    err: Error | null,
  ) => void;
};

type useDeleteCardPoliciesProps = {
  initialValues: {
    cardRuleIds: string[];
  };
  onSubmit: (
    val: {
      code: string;
      message: string;
    } | null,
    err: Error | null,
  ) => void;
};

type useRemoveCardPoliciesProps = {
  initialValues: {
    cardId: string;
  };
  onSubmit: (
    val: {
      code: string;
      message: string;
    } | null,
    err: Error | null,
  ) => void;
};

export const useApplyPolicyToCards = ({
  onSubmit,
  initialValues,
}: useApplyPolicyToCardsProps) => {
  const [applyCardPolicyFn] = useMutation<{
    applyCardPolicy: {
      message: string;
      code: string;
    };
  }>(APPLY_CARD_POLICY, {
    refetchQueries: [READ_APP_CARD_DATA],
  });

  const { ...restFormikValues } = useFormik({
    initialValues,
    onSubmit: async (value) => {
      try {
        const res = await applyCardPolicyFn({
          variables: {
            data: {
              ...value,
            },
          },
        });
        if (res?.data?.applyCardPolicy) {
          onSubmit(res?.data?.applyCardPolicy, null);
        }
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });

  return {
    ...restFormikValues,
  };
};

export const useDeleteCardPolicies = ({
  onSubmit,
  initialValues,
}: useDeleteCardPoliciesProps) => {
  const [deleteCardPolicyFn] = useMutation<{
    deleteCardPolicy: {
      message: string;
      code: string;
    };
  }>(DELETE_CARD_POLICY, {
    refetchQueries: [READ_CARD_POLICY],
  });

  const {
    handleSubmit: handleSubmitDelete,
    values: valuesDelete,
    isSubmitting: isSubmittingDelete,
    resetForm: resetFormDelete,
    setFieldValue: setFieldValueDelete,
    ...restFormikValues
  } = useFormik({
    initialValues,
    onSubmit: async (value) => {
      try {
        const res = await deleteCardPolicyFn({
          variables: {
            data: { ...value },
          },
        });
        if (res?.data?.deleteCardPolicy) {
          onSubmit(res?.data?.deleteCardPolicy, null);
        }
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });

  return {
    handleSubmitDelete,
    valuesDelete,
    isSubmittingDelete,
    resetFormDelete,
    setFieldValueDelete,
    ...restFormikValues,
  };
};

export const useRemoveCardPolicy = ({
  onSubmit,
  initialValues,
}: useRemoveCardPoliciesProps) => {
  const [removeCardPolicyFn, removeCardPolicyLoading] = useMutation<{
    removeCardPolicy: {
      message: string;
      code: string;
    };
  }>(REMOVE_CARD_POLICY, {
    refetchQueries: [READ_CARD_POLICY],
  });

  const handleRemoveCardPolicy = useCallback(
    async (cardId: string) => {
      try {
        const res = await removeCardPolicyFn({
          variables: {
            data: { ...initialValues, cardId }, // Include updated cardId here
          },
        });
        if (res?.data?.removeCardPolicy) {
          onSubmit(res?.data?.removeCardPolicy, null);
        }
      } catch (err) {
        if (err instanceof Error) {
          onSubmit(null, err);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
    [removeCardPolicyFn, initialValues, onSubmit],
  );

  return { handleRemoveCardPolicy, removeCardPolicyLoading };
};
