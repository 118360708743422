/* eslint-disable dot-notation */
/** @format */

import { dollarFormatter } from "@roadflex/constants";
import { TransactionType } from "@roadflex/types";
import { convertISOStringToDate } from "@roadflex/utils";
import { Dialog } from "primereact/dialog";
import { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { Button, ButtonSize, ButtonVariant } from "../../buttons";
import ExportImportModalLoader from "../../loader/export-import-modal-loader";

type ExportReceiptTransactionsModalProps = {
  open: boolean;
  onCloseExportModal: () => void;
  transactionTypes: TransactionType[];
  transactionLoading: boolean;
};

export const ExportReceiptTransactionsModal = ({
  open,
  onCloseExportModal,
  transactionTypes,
  transactionLoading,
}: ExportReceiptTransactionsModalProps) => {
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [showComplete, setShowComplete] = useState<boolean>(false);

  const csvLinkRef1 = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);

  const onExportCSV = () => {
    setShowLoading(true);
    setTimeout(() => {
      setShowLoading(false);
      setShowComplete(true);
      csvLinkRef1?.current?.link.click();
    }, 2000);
  };

  const [exportTransactionList, setExportTransactionList] = useState<
    {
      transactionDate: string;
      timezone: string;
      vehicleName: string;
      driverName: string;
      location: string;
      lastFour: string;
      merchantName: string;
      amount: string;
    }[]
  >([]);

  useEffect(() => {
    const transactions = transactionTypes.map((transaction) => {
      return {
        transactionDate: convertISOStringToDate(
          transaction?.transactionDate?.toString(),
          false,
          false,
          true,
        ),
        timezone: new Date().toTimeString().slice(9),
        driverName: transaction.driverName || "",
        vehicleName: transaction.vehicleName || "",
        vin: transaction.vehicleVIN || "",
        location: `${
          transaction?.stripeResponse?.merchant_data?.city
            ? `${transaction?.stripeResponse?.merchant_data?.city},`
            : ""
        } ${transaction?.merchantState || ""} ${
          transaction?.merchantPostCd || ""
        }`,
        lastFour: `${transaction.card.lastFour}`,
        merchantName: transaction.merchantName,
        amount: dollarFormatter(transaction.transactionAmount / 100),
        receiptUploaded: !transaction.receiptUploaded ? false : true,
      };
    });
    setExportTransactionList(transactions);
  }, [transactionTypes]);

  const transactionHeader = [
    { label: "Date", key: "transactionDate" },
    { label: "Timezone Reported In", key: "timezone" },
    { label: "Driver Name", key: "driverName" },
    { label: "Vehicle Name", key: "vehicleName" },
    { label: "Location", key: "location" },
    { label: "Card Last Four", key: "lastFour" },
    { label: "Merchant", key: "merchantName" },
    { label: "Amount", key: "amount" },
    { label: "Receipt Uploaded", key: "receiptUploaded" },
  ];

  const footer = (
    <div className="flex flex-row justify-end">
      <Button
        variant={ButtonVariant.GrayOutline}
        size={ButtonSize.Normal}
        onClick={() => {
          onCloseExportModal();
        }}
      >
        Close
      </Button>
      <CSVLink
        filename="transaction-receipt.csv"
        ref={csvLinkRef1}
        data={exportTransactionList}
        headers={transactionHeader}
        target="_blank"
      ></CSVLink>
      {!showLoading && !showComplete && (
        <Button
          variant={ButtonVariant.Black}
          size={ButtonSize.AppSize}
          loading={transactionLoading}
          onClick={() => onExportCSV()}
        >
          Export Transactions
        </Button>
      )}
    </div>
  );

  return (
    <Dialog
      header={
        <span className="text-base md:text-lg">Export Receipt Required</span>
      }
      visible={open}
      style={{ fontFamily: "Inter" }}
      className="w-[95%] sm:w-3/4 lg:max-w-[750px] md:max-w-[500px]"
      footer={footer}
      closable={false}
      onHide={onCloseExportModal}
    >
      <div className="space-y-3 text-sm md:text-base">
        {showLoading || showComplete ? (
          <ExportImportModalLoader
            showLoading={showLoading}
            showComplete={showComplete}
          />
        ) : (
          <div></div>
        )}
      </div>
    </Dialog>
  );
};
