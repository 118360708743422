import { UserAuthType } from "@roadflex/types";
import { Calendar, CalendarChangeParams } from "primereact/calendar";
import { DropdownChangeParams } from "primereact/dropdown";
import { Select } from "../../../dropdowns";
import { InlineLoader } from "../../../loader";
import FraudDeclinedTransactionReasonType from "./fraud-declined-transaction-reason-graph.ui";
import WeeklyFraudDeclinedTransaction from "./weekly-fraud-declined-transaction.ui";

type DisplayMode = {
  label: string;
  value: string;
};
interface FraudAnalysisProps {
  fraudAnalysisDataLoading: boolean;
  readFraudDeclinedTransactionReport?: {
    dailyFraudDeclinedTransactions: Array<[number, number]>;
    weeklyFraudDeclinedTransactions: Array<[number, number]>;
    categoryBreakdown: { [key: string]: string };
    code: string;
    message: string;
  };
  dates: Date[] | Date | undefined;
  onDateRangeChange: (e: CalendarChangeParams) => void;
  startDate: Date;
  endDate: Date;
  displayModes: DisplayMode[];
  selectedDisplayMode: string;
  onDisplayModeChange: (e: DropdownChangeParams) => void;
  readCurrentUserAuth: UserAuthType;
}
export const FraudAnalysisUI = ({
  fraudAnalysisDataLoading,
  readFraudDeclinedTransactionReport,
  dates,
  onDateRangeChange,
  startDate,
  endDate,
  displayModes,
  selectedDisplayMode,
  onDisplayModeChange,
  readCurrentUserAuth,
}: FraudAnalysisProps) => {
  return (
    <div className="text-sm">
      <div className="col-span-2">
        <div className="flex flex-col justify-start gap-4 md:gap-10 md:items-center md:flex-row">
          <div className="w-full md:w-fit">
            <div className="flex flex-row items-center">
              <label className="pr-2 font-semibold" htmlFor="display-mode">
                Display Mode
              </label>
              <Select
                id="display-mode"
                value={selectedDisplayMode}
                options={displayModes}
                optionLabel="label"
                optionValue="value"
                onChange={onDisplayModeChange}
                placeholder="Select Mode"
                itemTemplate={(option) => {
                  return (
                    <span className={`mode-${option.label}`}>
                      {option.label}
                    </span>
                  );
                }}
              ></Select>
            </div>
          </div>
          <div className="flex flex-row items-center">
            <label className="flex flex-col pr-2 font-semibold" htmlFor="range">
              <div>
                Date Range (
                {readCurrentUserAuth?.accountDisplaySetting?.timeZone})
              </div>
              <div className="italic font-normal">Max Range: 1 month</div>
            </label>
            <Calendar
              id="range"
              value={dates}
              onChange={onDateRangeChange}
              selectionMode="range"
              placeholder="Select Date Range"
              className="custom-calendar"
              panelClassName="custom-calendar-panel"
              minDate={startDate}
              maxDate={endDate}
              inputClassName="!py-2"
              readOnlyInput
            />
          </div>
          {fraudAnalysisDataLoading && <InlineLoader />}
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 mt-4 md:mt-8 md:p-4 xl:grid-cols-2">
        <div className="">
          <WeeklyFraudDeclinedTransaction
            fraudDeclinedDailyTransactions={
              readFraudDeclinedTransactionReport?.dailyFraudDeclinedTransactions ||
              []
            }
            fraudDeclinedWeeklyTransactions={
              readFraudDeclinedTransactionReport?.weeklyFraudDeclinedTransactions ||
              []
            }
            loading={false}
            title={`Fraud Declined Transactions each ${selectedDisplayMode}`}
            selectedDisplayMode={selectedDisplayMode}
          ></WeeklyFraudDeclinedTransaction>
        </div>
        <div className="">
          <FraudDeclinedTransactionReasonType
            categoryBreakdown={
              readFraudDeclinedTransactionReport?.categoryBreakdown || {}
            }
            title={`Fraud Declined Transaction Reason each ${selectedDisplayMode}`}
          ></FraudDeclinedTransactionReasonType>
        </div>
      </div>
    </div>
  );
};
