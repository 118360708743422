/** @format */
import { usePlatformDetect } from "@roadflex/react-hooks";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { newPaginationTemplate } from "../../../datatable/pagination-templates/new-pagination-template";
import MerchantCategoryBreakdownGraph from "../../graphs/merchant-category-breakdown-graph.ui";
import WeeklySpendGraph from "../../graphs/weekly-spend-graph.ui";

type FleetPerformanceUIProps = {
  loading: boolean;
  weeklySpendsData: Array<[number, number]>;
  categoryBreakdownData: { [key: string]: string };
  blockedTransactionsList: {
    declineReason: string;
    numberOfOccurrences: number;
    numberOfUniqueDrivers: number;
  }[];
};

const FleetPerformanceUI = ({
  loading,
  weeklySpendsData,
  categoryBreakdownData,
  blockedTransactionsList,
}: FleetPerformanceUIProps) => {
  const { isMobile } = usePlatformDetect();

  return (
    <div className="text-sm md:mt-0 md:text-base h-full md:min-h-[calc(100vh-96px)]">
      <div className="grid grid-cols-1 gap-4 md:mt-2 xl:grid-cols-2 ">
        <div className="">
          <WeeklySpendGraph
            weeklySpendsData={weeklySpendsData}
            dashboardLoading={loading}
          ></WeeklySpendGraph>
        </div>
        <div className="">
          <MerchantCategoryBreakdownGraph
            categoryBreakdown={categoryBreakdownData}
            dashboardLoading={loading}
          ></MerchantCategoryBreakdownGraph>
        </div>
      </div>

      <div className="flex flex-col w-full p-4 mt-4 bg-white rounded-md shadow md:mt-8 md:p-8 ">
        <div className="font-semibold text-brown-500 md:text-lg">
          {" "}
          Fraud Prevention: Blocked transactions (Lifetime)
        </div>
        <div className="flex flex-col h-full mt-4">
          <DataTable
            size="small"
            stripedRows
            value={blockedTransactionsList}
            loading={loading}
            dataKey="declineReason"
            style={{
              fontFamily: "Inter",
            }}
            responsiveLayout={"scroll"}
            emptyMessage="No available records"
            pageLinkSize={isMobile() ? 2 : 3}
            className="w-auto min-w-full custom-table"
            sortMode={"single"}
            sortField="declineReason"
            sortOrder={1}
            paginator
            rows={10}
            paginatorTemplate={newPaginationTemplate}
          >
            <Column
              sortable={!isMobile()}
              field="declineReason"
              header="Reason the transaction was declined"
              className="text-sm 5xl:text-base"
              bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
              headerClassName="!bg-white !text-brown-500 !border-brown-500 "
            />
            <Column
              // filter
              sortable={!isMobile()}
              field="numberOfOccurrences"
              header="Total number of occurrences"
              className="text-sm 5xl:text-base"
              bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
              headerClassName="!bg-white !text-brown-500 !border-brown-500"
            />
            <Column
              // filter
              sortable={!isMobile()}
              field="numberOfUniqueDrivers"
              header="Number of unique drivers involved"
              className="text-sm 5xl:text-base"
              bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
              headerClassName="!bg-white !text-brown-500 !border-brown-500"
            />
          </DataTable>
        </div>
      </div>
    </div>
  );
};

export default FleetPerformanceUI;
