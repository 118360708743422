/** @format */

import {
  ChevronRightIcon,
  ExclamationCircleIcon,
  LifebuoyIcon,
  MinusCircleIcon,
} from "@heroicons/react/24/outline";
import {
  ENUM_CHILD_USER_STATUS,
  ENUM_CHILD_USER_TYPE,
  ENUM_NOTIFICATION_CATEGORY,
  ENUM_NOTIFICATION_NAME,
} from "@prisma/client";
import {
  CATEGORIES_MASTER,
  CATEGORY_LABELS,
  LABELS_FLAGGED_TRANSACTION,
  LABELS_FUEL_TYPE,
  LABELS_TRANSACTION_ABOVE,
  LABEL_TRANSACTION_REJECT,
} from "@roadflex/constants";
import {
  AccountTagType,
  ChildUserType,
  NotificationsTypeExtended,
  ParameterFilterType,
  TabsType,
  UserAuthType,
} from "@roadflex/types";
import { humanize, phoneFormatter } from "@roadflex/utils";
import classNames from "classnames";
import { FormikProps } from "formik";
import { useRouter } from "next/router";
import { InputSwitch } from "primereact/inputswitch";
import { Tooltip } from "primereact/tooltip";
import { useState } from "react";
import {
  Button,
  ButtonSize,
  ButtonType,
  ButtonVariant,
} from "../../../buttons";
import { CustomMultiSelect, Select } from "../../../dropdowns";
import { CheckboxInput, RadioInput, TextInput } from "../../../inputs";

interface UserUIProps extends FormikProps<ChildUserType> {
  tabs: TabsType[];
  notifications: NotificationsTypeExtended[];
  setNotifications: (value: NotificationsTypeExtended[]) => void;
  parameterFilter?: ParameterFilterType[];
  setParameterFilter?: (value: ParameterFilterType[]) => void;
  userId?: string;
  update: boolean;
  setUpdate: (value: boolean) => void;
  cardtags?: AccountTagType[];
  accountUsersLoading?: boolean;
  readCurrentUserAuth?: UserAuthType;
}

export const UserUI = ({
  readCurrentUserAuth,
  tabs,
  notifications,
  setNotifications,
  parameterFilter,
  setParameterFilter,
  handleChange,
  values,
  handleSubmit,
  handleBlur,
  errors,
  touched,
  setFieldValue,
  userId,
  isSubmitting,
  setValues,
  update,
  setUpdate,
  cardtags,
  accountUsersLoading = false,
}: UserUIProps) => {
  const router = useRouter();
  const [tabOpen, setTabOpen] = useState(1);

  const backToMultipleUsersPanel = () => {
    router.push("/multiple-users");
  };

  const revertValues = () => {
    setValues((prevValues) => ({
      ...prevValues,
      userNotification: notifications,
      parameterFilter: parameterFilter,
    }));
  };

  const handleOptionCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    idx: number,
    optionValue: string,
  ) => {
    const isChecked = e.target.checked;
    const currentValues = values.userNotification[idx].optionValues || [];
    if (isChecked) {
      // Add new option
      setFieldValue(`userNotification[${idx}].optionValues`, [
        ...currentValues,
        optionValue,
      ]);
    } else {
      // Remove option
      setFieldValue(
        `userNotification[${idx}].optionValues`,
        currentValues.filter((val: string) => val !== optionValue),
      );
    }
  };

  const handleTransactionAboveRadioChange = (idx: number, value: string) => {
    setFieldValue(`userNotification[${idx}].variables`, value);
  };

  const handleOptionValuesMultiSelectChange = (
    idx: number,
    newValues: string[],
  ) => {
    setFieldValue(`userNotification[${idx}].optionValues`, newValues);
  };

  return (
    <div className="flex-col flex flex-1 md:px-4 md:pb-4 mt-0 m-4 h-full md:min-h-[calc(100vh-96px)]">
      <div className="">
        <div className="flex flex-col w-full rounded-md md:my-12">
          <div className="flex flex-col justify-between md:flex-row">
            <div className="flex flex-row items-center">
              <div className="">
                <Button
                  size={ButtonSize.Small}
                  variant={ButtonVariant.GrayOutline}
                  onClick={backToMultipleUsersPanel}
                  className="pl-0 ml-[-6px] border-0"
                >
                  <span className="flex items-center h-5">
                    <ChevronRightIcon
                      className={
                        "-rotate-180 h-5 w-5 transform ease-in duration-300"
                      }
                      aria-hidden="true"
                    />
                  </span>
                  Back
                </Button>
              </div>
              <div className="ml-4 text-sm font-medium md:text-lg ">
                {userId
                  ? `User - ${values.firstName} ${values.lastName}`
                  : "Add New User"}
              </div>
            </div>
            {userId && (
              <div className="flex flex-row items-center">
                <div
                  className={classNames(
                    values.status === ENUM_CHILD_USER_STATUS.ACCOUNT_ACTIVE
                      ? "text-green-700 py-1 px-2"
                      : "text-red-600 py-1 px-2",
                    "ml-4 uppercase",
                  )}
                >
                  {values.status === ENUM_CHILD_USER_STATUS.ACCOUNT_ACTIVE && (
                    <>Active</>
                  )}
                  {values.status ===
                    ENUM_CHILD_USER_STATUS.ACCOUNT_INACTIVE && <>Inactive</>}
                </div>
                <div>
                  <Button
                    onClick={() => {
                      revertValues();
                      setFieldValue(
                        "status",
                        values.status === ENUM_CHILD_USER_STATUS.ACCOUNT_ACTIVE
                          ? ENUM_CHILD_USER_STATUS.ACCOUNT_INACTIVE
                          : ENUM_CHILD_USER_STATUS.ACCOUNT_ACTIVE,
                      );
                      handleSubmit();
                    }}
                    size={ButtonSize.AppSize}
                    variant={
                      values.status === ENUM_CHILD_USER_STATUS.ACCOUNT_ACTIVE
                        ? ButtonVariant.Red
                        : ButtonVariant.Green
                    }
                    loading={isSubmitting}
                    disabled={isSubmitting || accountUsersLoading}
                    className={classNames(
                      values.status === ENUM_CHILD_USER_STATUS.ACCOUNT_ACTIVE
                        ? "text-sm md:text-base"
                        : "text-sm md:text-base",
                    )}
                  >
                    {values.status === ENUM_CHILD_USER_STATUS.ACCOUNT_ACTIVE
                      ? "Deactivate User"
                      : "Activate User"}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="w-full mt-5 md:mt-0">
          <div className="border-b border-brown-500">
            <nav className="flex -mb-px space-x-8 overflow-auto">
              {tabs.map((tab) => (
                <a
                  key={tab.name}
                  href={tab.href}
                  className={classNames(
                    tabOpen === tab.id
                      ? "border-black text-black"
                      : "border-transparent hover:border-black",
                    "whitespace-nowrap py-2 px-1 border-b-2 font-medium text-sm cursor-pointer",
                  )}
                  onClick={(e) => {
                    e.preventDefault();
                    setTabOpen(tab.id);
                  }}
                >
                  {tab.name}
                </a>
              ))}
            </nav>
          </div>

          <div className={`${tabOpen === 1 ? `block` : `hidden`} mt-6`}>
            <form
              className="flex flex-col h-full p-8 overflow-y-auto bg-white rounded-md shadow"
              onSubmit={handleSubmit}
            >
              <div className="flex flex-col flex-grow h-full py-8 space-y-4">
                <div className="grid grid-cols-12 gap-4">
                  <div className="relative col-span-12 sm:col-span-12">
                    <TextInput
                      label={"First Name"}
                      required
                      value={values.firstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={userId !== undefined && !update}
                      name="firstName"
                      id="firstName"
                      touched={touched.firstName}
                      error={touched.firstName && Boolean(errors.firstName)}
                      errorMessage={errors.firstName}
                    ></TextInput>
                  </div>
                  <div className="relative col-span-12 sm:col-span-12">
                    <TextInput
                      label={"Last Name"}
                      required
                      value={values.lastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={userId !== undefined && !update}
                      name="lastName"
                      id="lastName"
                      touched={touched.lastName}
                      error={touched.lastName && Boolean(errors.lastName)}
                      errorMessage={errors.lastName}
                    ></TextInput>
                  </div>
                  <div className="relative col-span-12 sm:col-span-12">
                    <TextInput
                      label={"Email"}
                      required
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={userId !== ""}
                      name="email"
                      id="email"
                      touched={touched.email}
                      error={touched.email && Boolean(errors.email)}
                      errorMessage={errors.email}
                    ></TextInput>
                  </div>
                  <div className="relative col-span-12 sm:col-span-12">
                    <TextInput
                      type="tel"
                      label={"Phone Number"}
                      value={values.phoneNumber}
                      onChange={(event) => {
                        setFieldValue(
                          "phoneNumber",
                          phoneFormatter(event?.target?.value),
                        );
                      }}
                      onBlur={handleBlur}
                      disabled={userId !== undefined && !update}
                      name="phoneNumber"
                      id="phoneNumber"
                      touched={touched.phoneNumber}
                      error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                      errorMessage={errors.phoneNumber}
                    ></TextInput>
                  </div>
                  <div className="relative col-span-12 mt-8 sm:col-span-12">
                    <div className="block mb-1">Role</div>
                    <div className="">
                      <RadioInput
                        type="radio"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={ENUM_CHILD_USER_TYPE.ACCOUNT_MANAGER}
                        checked={
                          values.roleName ===
                          ENUM_CHILD_USER_TYPE.ACCOUNT_MANAGER
                        }
                        name="roleName"
                        disabled={userId !== undefined && !update}
                        label="Account Manager"
                        className="!rounded"
                      />
                      <div className="text-sm italic">
                        <span>
                          Account managers can manage all users, cards, vehicles
                          and policies (similar to the account owner). They can
                          manage billing and subscriptions.
                        </span>
                      </div>
                    </div>
                    <div className="mt-2">
                      <RadioInput
                        type="radio"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={ENUM_CHILD_USER_TYPE.CARD_ADMIN}
                        checked={
                          values.roleName === ENUM_CHILD_USER_TYPE.CARD_ADMIN
                        }
                        name="roleName"
                        disabled={userId !== undefined && !update}
                        label="Card Admin"
                        className="!rounded"
                      />
                      <div className="text-sm italic">
                        <span>
                          Card admins add other users, order cards, set spend
                          limits and manage policies.
                        </span>
                      </div>
                    </div>
                    <div className="mt-2">
                      <RadioInput
                        type="radio"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={ENUM_CHILD_USER_TYPE.CARD_MANAGER}
                        checked={
                          values.roleName === ENUM_CHILD_USER_TYPE.CARD_MANAGER
                        }
                        name="roleName"
                        disabled={userId !== undefined && !update}
                        label="Card Manager"
                        className="!rounded"
                      />
                      <div className="text-sm italic">
                        <span>
                          Card managers can manage drivers, vehicles and order
                          cards.
                        </span>
                      </div>
                    </div>
                    <div className="mt-2">
                      <RadioInput
                        type="radio"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={ENUM_CHILD_USER_TYPE.BOOKKEEPER}
                        checked={
                          values.roleName === ENUM_CHILD_USER_TYPE.BOOKKEEPER
                        }
                        name="roleName"
                        disabled={userId !== undefined && !update}
                        label="Book Keeper"
                        className="!rounded"
                      />
                      <div className="text-sm italic">
                        <span>
                          Book keepers can manage billing and subscriptions.
                        </span>
                      </div>
                    </div>
                    {touched.roleName && errors?.roleName && (
                      <>
                        <Tooltip
                          target=".roleName-error"
                          position="top"
                          className="red-tooltip"
                          content={errors.roleName}
                        ></Tooltip>

                        <div className="absolute h-[34px] bottom-0 right-0 pr-2 flex items-center">
                          <ExclamationCircleIcon
                            className="w-5 h-5 text-red-500 roleName-error"
                            aria-hidden="true"
                          />
                        </div>
                      </>
                    )}
                  </div>

                  {/* Restrict Card and Transaction View */}
                  {userId !== "" && userId !== null && (
                    <div className="relative col-span-12 mt-8 sm:col-span-12">
                      <div className="w-full text-sm font-medium text-left md:text-base">
                        Restrict Card and Transaction View for user
                        <div className="bg-gray-700 rounded-md">
                          <div className="flex items-center justify-start p-4 pb-2 text-white">
                            <LifebuoyIcon
                              className="flex-shrink-0 w-4 h-4"
                              aria-hidden="true"
                            />
                            <span className="ml-2 font-bold">Help Guide</span>
                          </div>
                          <p className="px-4 pb-4 text-xs font-medium text-white md:text-sm">
                            By selecting the card tag permissions, only the
                            cards and associated transactions assigned to that
                            card tag will be shown to this user. Policies can
                            only be applied to this group of cards with read and
                            write permission. If nothing is selected, all cards
                            and transactions can be viewed by this user.
                          </p>
                        </div>
                        {cardtags &&
                          cardtags.map((entry: AccountTagType, idx) => {
                            // Check if this tag is already set in parameterFilter
                            const existingParam = values?.parameterFilter?.find(
                              (x) => x.tag === entry.id,
                            );
                            return (
                              <div className="w-full mt-4" key={entry?.id}>
                                <div className="relative flex items-center justify-between">
                                  <div className="mr-3 text-xs text-left">
                                    <label
                                      htmlFor={`${entry?.title}`}
                                      className="block text-sm font-normal text-left md:text-base text-blue-gray-900"
                                    >
                                      {entry?.title} ({entry?.tagId})
                                    </label>
                                  </div>
                                  <div className="flex items-center ml-4 text-left">
                                    <Select
                                      value={
                                        existingParam?.readWritePermission ||
                                        "NONE"
                                      }
                                      onChange={(e) => {
                                        const arrayObject =
                                          values?.parameterFilter?.filter(
                                            (x) => x.tag !== entry?.id,
                                          ) || [];
                                        if (e.value !== "NONE") {
                                          if (entry?.id) {
                                            arrayObject.push({
                                              tag: entry?.id,
                                              readWritePermission: e.value,
                                            });
                                          }
                                        }
                                        setFieldValue(
                                          "parameterFilter",
                                          arrayObject,
                                        );
                                      }}
                                      className="min-w-[200px]"
                                      disabled={!update}
                                      options={[
                                        {
                                          label: "NONE",
                                          value: "NONE",
                                          disabled: false,
                                        },
                                        {
                                          label: "READ",
                                          value: "READ",
                                          disabled: false,
                                        },
                                        {
                                          label: "READ AND WRITE",
                                          value: "READ_AND_WRITE",
                                          disabled: false,
                                        },
                                      ]}
                                    ></Select>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="flex flex-row justify-end py-4">
                {userId ? (
                  <>
                    {!update && (
                      <div className="flex flex-row justify-end w-full mt-8">
                        <Button
                          variant={ButtonVariant.AppOrange}
                          size={ButtonSize.AppSize}
                          className=""
                          onClick={() => {
                            setUpdate(true);
                          }}
                          disabled={accountUsersLoading}
                        >
                          Update
                        </Button>
                      </div>
                    )}
                    {update && (
                      <div className="flex flex-row justify-end w-full mt-8">
                        <Button
                          variant={ButtonVariant.Red}
                          size={ButtonSize.AppSize}
                          className=""
                          onClick={() => {
                            setUpdate(false);
                            revertValues();
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant={ButtonVariant.Primary}
                          size={ButtonSize.AppSize}
                          className="ml-4"
                          disabled={isSubmitting}
                          loading={isSubmitting}
                          type={ButtonType.Submit}
                        >
                          Save
                        </Button>
                      </div>
                    )}
                  </>
                ) : (
                  <Button
                    variant={ButtonVariant.AppOrange}
                    size={ButtonSize.AppSize}
                    loading={isSubmitting}
                    disabled={isSubmitting}
                    type={ButtonType.Submit}
                    className="flex flex-row items-center w-full sm:w-auto"
                  >
                    Add New User
                  </Button>
                )}
              </div>
            </form>
          </div>

          <div className={`${tabOpen === 2 ? `block` : `hidden`} mt-6`}>
            <form className="text-sm md:text-base" onSubmit={handleSubmit}>
              {readCurrentUserAuth?.fuelCard?.customerType.includes(
                "CREDIT",
              ) && (
                <div className="py-8 mt-8 bg-white rounded-md shadow md:text-base">
                  {userId !== "" && userId !== null && (
                    <div className="flex flex-col gap-8 overflow-hidden">
                      <div className="px-4 font-medium">
                        <div className="font-semibold text-brown-500 md:text-lg">
                          Billing and Payment Notifications (Applicable to
                          Credit Accounts Only)
                        </div>
                      </div>
                      <div className="h-0.5 border-0 bg-brown-500"></div>
                      <div className="items-center w-full px-4 ">
                        {values?.userNotification.map(
                          (entry: NotificationsTypeExtended, idx: number) => {
                            if (
                              entry?.name ===
                              ENUM_NOTIFICATION_NAME.STATEMENT_AVAILABLE
                            ) {
                              return (
                                <div className="w-full" key={entry?.name}>
                                  <div className="flex flex-row items-center justify-between w-full">
                                    <div className="mr-3 text-xs text-left">
                                      <label className="block text-sm text-left md:text-base text-blue-gray-900">
                                        {entry?.description}
                                      </label>
                                    </div>
                                    <div className="flex items-center ml-4 text-left">
                                      <span
                                        className={classNames(
                                          entry?.isEmailDisabled
                                            ? "text-gray-300"
                                            : "",
                                          "mx-4",
                                        )}
                                      >
                                        Email
                                      </span>
                                      {entry.isEmailDisabled && (
                                        <div className="ml-2 mr-3">
                                          <MinusCircleIcon
                                            className="text-gray-300 w-7 h-7"
                                            aria-hidden="true"
                                          />
                                        </div>
                                      )}
                                      {!entry.isEmailDisabled && (
                                        <InputSwitch
                                          name={`userNotification[${idx}].email`}
                                          id={`userNotification[${idx}].email`}
                                          checked={entry?.email}
                                          onChange={(e) =>
                                            setFieldValue(
                                              `userNotification[${idx}].email`,
                                              e.value,
                                            )
                                          }
                                          className="border-0 input-switch"
                                          disabled={
                                            entry?.isEmailDisabled || !update
                                          }
                                        />
                                      )}

                                      <span
                                        className={classNames(
                                          entry?.isSmsDisabled
                                            ? "text-gray-300"
                                            : "",
                                          "mx-4",
                                        )}
                                      >
                                        SMS
                                      </span>
                                      {entry.isSmsDisabled && (
                                        <div className="ml-2 mr-3">
                                          <MinusCircleIcon
                                            className="text-gray-300 w-7 h-7"
                                            aria-hidden="true"
                                          />
                                        </div>
                                      )}
                                      {!entry.isSmsDisabled && (
                                        <InputSwitch
                                          name={`userNotification[${idx}].sms`}
                                          id={`userNotification[${idx}].sms`}
                                          checked={entry?.sms}
                                          onChange={(e) =>
                                            setFieldValue(
                                              `userNotification[${idx}].sms`,
                                              e.value,
                                            )
                                          }
                                          className="border-0 input-switch"
                                          disabled={
                                            entry?.isSmsDisabled || !update
                                          }
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                            return null; // Return nothing if it's not statement_available
                          },
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}

              {/* Transaction Notifications */}
              <div className="py-8 mt-8 bg-white rounded-md shadow md:text-base">
                {userId !== "" && userId !== null && (
                  <div className="flex flex-col gap-8 overflow-hidden">
                    <div className="px-4 font-medium">
                      <div className="font-semibold text-brown-500 md:text-lg">
                        Transaction Notifications
                      </div>
                    </div>
                    <div className="h-0.5 border-0 bg-brown-500"></div>
                    <div className="items-center w-full px-4">
                      {values?.userNotification
                        .map((notif, originalIndex) => ({
                          ...notif,
                          originalIndex,
                        }))
                        ?.filter(
                          (notification) =>
                            notification.category ===
                            ENUM_NOTIFICATION_CATEGORY.TRANSACTION,
                        )
                        .map((entry, idx) => (
                          <div className="w-full mb-8" key={entry?.name}>
                            <div className="flex flex-row items-center justify-between w-full">
                              <span className="">
                                <span className="text-left">
                                  {entry.description}
                                </span>
                                {entry?.name ===
                                  ENUM_NOTIFICATION_NAME.TRANSACTION_APPROVED && (
                                  <span className="font-normal text-yellow-600">
                                    {" "}
                                    (Enterprise)
                                  </span>
                                )}
                              </span>
                              <div className="flex items-center ml-4 text-left">
                                <span
                                  className={classNames(
                                    entry?.isEmailDisabled
                                      ? "text-gray-300"
                                      : "",
                                    "mx-4",
                                  )}
                                >
                                  Email
                                </span>
                                {entry.isEmailDisabled && (
                                  <div className="ml-2 mr-3">
                                    <MinusCircleIcon
                                      className="text-gray-300 w-7 h-7"
                                      aria-hidden="true"
                                    />
                                  </div>
                                )}
                                {!entry.isEmailDisabled && (
                                  <InputSwitch
                                    name={`userNotification[${entry.originalIndex}].email`}
                                    id={`userNotification[${entry.originalIndex}].email`}
                                    checked={entry?.email}
                                    onChange={(e) =>
                                      setFieldValue(
                                        `userNotification[${entry.originalIndex}].email`,
                                        e.value,
                                      )
                                    }
                                    className="border-0 input-switch"
                                    disabled={entry?.isEmailDisabled || !update}
                                  />
                                )}

                                <span
                                  className={classNames(
                                    entry?.isSmsDisabled ? "text-gray-300" : "",
                                    "mx-4",
                                  )}
                                >
                                  SMS
                                </span>
                                {entry.isSmsDisabled && (
                                  <div className="ml-2 mr-3">
                                    <MinusCircleIcon
                                      className="text-gray-300 w-7 h-7"
                                      aria-hidden="true"
                                    />
                                  </div>
                                )}
                                {!entry.isSmsDisabled && (
                                  <InputSwitch
                                    name={`userNotification[${entry.originalIndex}].sms`}
                                    id={`userNotification[${entry.originalIndex}].sms`}
                                    checked={entry?.sms}
                                    onChange={(e) =>
                                      setFieldValue(
                                        `userNotification[${entry.originalIndex}].sms`,
                                        e.value,
                                      )
                                    }
                                    className="border-0 input-switch"
                                    disabled={entry?.isSmsDisabled || !update}
                                  />
                                )}
                              </div>
                            </div>

                            {/* DECLINE NOTIFICATION TYPES */}
                            {entry?.name ===
                              ENUM_NOTIFICATION_NAME.TRANSACTION_DECLINED && (
                              <div>
                                <div className="relative col-span-12 my-4 sm:col-span-12">
                                  <div className="mb-2 text-xs whitespace-nowrap lg:text-sm">
                                    Decline Notification Types (Select the
                                    decline notifications you want to receive)
                                  </div>
                                  {LABEL_TRANSACTION_REJECT.map((options) => (
                                    <div
                                      className="relative flex items-start col-span-12 sm:col-span-12"
                                      key={options.value}
                                    >
                                      <div className="flex items-center h-5 col-span-12 sm:col-span-12">
                                        <CheckboxInput
                                          disabled={
                                            !update ||
                                            (!entry.email && !entry.sms)
                                          }
                                          label={humanize(options.label)}
                                          labelClass="text-sm"
                                          checked={entry?.optionValues.includes(
                                            options.value,
                                          )}
                                          onChange={(e) =>
                                            handleOptionCheckboxChange(
                                              e,
                                              entry.originalIndex,
                                              options.value,
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            )}

                            {/* TRANSACTION ABOVE AMOUNT */}
                            {entry?.name ===
                              ENUM_NOTIFICATION_NAME.TRANSACTION_ABOVE_AMOUNT && (
                              <div>
                                <div className="relative col-span-12 my-4 sm:col-span-12">
                                  <div className="mb-2 text-xs whitespace-nowrap lg:text-sm">
                                    Select the amount that will trigger the
                                    notification
                                  </div>
                                  {LABELS_TRANSACTION_ABOVE.map((options) => (
                                    <div
                                      className="relative flex items-start col-span-12 sm:col-span-12"
                                      key={`transactionAboveGroup${options.label}`}
                                    >
                                      <div className="flex items-center h-5 col-span-12 sm:col-span-12">
                                        <RadioInput
                                          label={humanize(options.label)}
                                          labelClass="text-sm"
                                          name={`transactionAboveGroup${idx}`}
                                          className="!rounded"
                                          disabled={
                                            !update ||
                                            (!entry.email && !entry.sms)
                                          }
                                          checked={
                                            entry?.variables === options.value
                                          }
                                          value={options.value}
                                          id={`transactionAboveLabel${options.label}`}
                                          onChange={() =>
                                            handleTransactionAboveRadioChange(
                                              entry.originalIndex,
                                              options.value,
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            )}

                            {/* FLAGGED NOTIFICATION TYPES */}
                            {entry?.name ===
                              ENUM_NOTIFICATION_NAME.TRANSACTION_FLAGGED && (
                              <div>
                                <div className="relative col-span-12 my-4 sm:col-span-12">
                                  <div className="mb-2 text-xs whitespace-nowrap lg:text-sm">
                                    Flagged Notification Types (Select the
                                    flagged notifications you want to receive)
                                  </div>
                                  {LABELS_FLAGGED_TRANSACTION.map((options) => (
                                    <div
                                      className="relative flex items-start col-span-12 sm:col-span-12"
                                      key={options.value}
                                    >
                                      <div className="flex items-center h-5 col-span-12 sm:col-span-12">
                                        <CheckboxInput
                                          disabled={
                                            !update ||
                                            (!entry.email && !entry.sms)
                                          }
                                          label={humanize(options.label)}
                                          labelClass="text-sm"
                                          checked={entry?.optionValues.includes(
                                            options.value,
                                          )}
                                          onChange={(e) =>
                                            handleOptionCheckboxChange(
                                              e,
                                              entry.originalIndex,
                                              options.value,
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                  ))}
                                  <div className="pb-4 sm:flex sm:flex-row-reverse"></div>
                                </div>
                              </div>
                            )}

                            {/* TRANSACTION FUEL TYPE */}
                            {entry?.name ===
                              ENUM_NOTIFICATION_NAME.TRANSACTION_FUEL_TYPE && (
                              <div>
                                <div className="relative col-span-12 my-4 sm:col-span-12">
                                  <div className="mb-2 text-xs whitespace-nowrap lg:text-sm">
                                    Fuel Types
                                  </div>
                                  {LABELS_FUEL_TYPE.map((options) => (
                                    <div
                                      className="relative flex items-start col-span-12 sm:col-span-12"
                                      key={options.value}
                                    >
                                      <div className="flex items-center h-5 col-span-12 sm:col-span-12">
                                        <CheckboxInput
                                          disabled={
                                            !update ||
                                            (!entry.email && !entry.sms)
                                          }
                                          label={humanize(options.label)}
                                          labelClass="text-sm"
                                          checked={entry?.optionValues.includes(
                                            options.value,
                                          )}
                                          onChange={(e) =>
                                            handleOptionCheckboxChange(
                                              e,
                                              entry.originalIndex,
                                              options.value,
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            )}

                            {/* TRANSACTION APPROVED (Merchant Category) */}
                            {entry?.name ===
                              ENUM_NOTIFICATION_NAME.TRANSACTION_APPROVED && (
                              <div>
                                <div className="relative col-span-12 my-4 sm:col-span-12">
                                  <div className="mb-2 text-xs whitespace-nowrap lg:text-sm">
                                    Select Merchant Category
                                  </div>
                                  <CustomMultiSelect
                                    value={entry?.optionValues}
                                    onChange={(e) =>
                                      handleOptionValuesMultiSelectChange(
                                        entry.originalIndex,
                                        e.value,
                                      )
                                    }
                                    options={[
                                      {
                                        label: "-- Select multiple options --",
                                        value: "",
                                        disabled: true,
                                      },
                                      {
                                        label: "Auto Service Shops (7538)",
                                        value: "auto_service_shops",
                                        disabled:
                                          !update ||
                                          (!entry.email && !entry.sms),
                                      },
                                    ].concat(
                                      CATEGORIES_MASTER.split(",").map(
                                        (category) => {
                                          return {
                                            label:
                                              CATEGORY_LABELS[category]
                                                ?.label || humanize(category),
                                            value: category,
                                            disabled:
                                              !update ||
                                              (!entry.email && !entry.sms),
                                          };
                                        },
                                      ),
                                    )}
                                    placeholder="-- Select multiple options --"
                                    className="max-w-[250px]"
                                    optionLabel="label"
                                    optionValue="value"
                                    maxSelectedLabels={1}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>

              {/* Account Notifications */}
              <div className="py-8 mt-8 bg-white rounded-md shadow md:text-base">
                <div className="flex flex-col gap-8 overflow-hidden ">
                  <div className="px-4 font-medium">
                    <div className="font-semibold text-brown-500 md:text-lg">
                      Account Notifications
                    </div>
                  </div>
                  <div className="h-0.5 border-0 bg-brown-500"></div>
                  <div className="items-center w-full px-4">
                    {values?.userNotification
                      .map((notif, originalIndex) => ({
                        ...notif,
                        originalIndex,
                      }))
                      ?.filter(
                        (notification) =>
                          notification.category ===
                          ENUM_NOTIFICATION_CATEGORY.ACCOUNT,
                      )
                      .map((entry, idx) => (
                        <div className="w-full" key={entry.name}>
                          <div className="flex flex-row items-center justify-between w-full mb-8">
                            <div className="text-left">{entry.description}</div>
                            <div className="flex items-center ml-4 text-left">
                              <div className="flex flex-col">
                                <div className="flex items-center">
                                  <span
                                    className={classNames(
                                      entry?.isEmailDisabled
                                        ? "text-gray-300"
                                        : "",
                                      "mr-2",
                                    )}
                                  >
                                    Email
                                  </span>
                                  {!entry.isEmailDisabled && (
                                    <InputSwitch
                                      name={`userNotification[${entry.originalIndex}].email`}
                                      id={`userNotification[${entry.originalIndex}].email`}
                                      checked={entry?.email}
                                      onChange={(e) =>
                                        setFieldValue(
                                          `userNotification[${entry.originalIndex}].email`,
                                          e.value,
                                        )
                                      }
                                      className="border-0 input-switch"
                                      disabled={
                                        entry?.isEmailDisabled || !update
                                      }
                                    />
                                  )}
                                  {entry.isEmailDisabled && (
                                    <div className="ml-2 mr-3">
                                      <MinusCircleIcon
                                        className="text-gray-300 w-7 h-7"
                                        aria-hidden="true"
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="flex flex-col">
                                <div className="flex items-center">
                                  <span
                                    className={classNames(
                                      entry?.isSmsDisabled
                                        ? "text-gray-300"
                                        : "",
                                      "mx-3",
                                    )}
                                  >
                                    SMS
                                  </span>
                                  {!entry.isSmsDisabled && (
                                    <InputSwitch
                                      name={`userNotification[${entry.originalIndex}].sms`}
                                      id={`userNotification[${entry.originalIndex}].sms`}
                                      checked={entry?.sms}
                                      onChange={(e) =>
                                        setFieldValue(
                                          `userNotification[${entry.originalIndex}].sms`,
                                          e.value,
                                        )
                                      }
                                      className="border-0 input-switch"
                                      disabled={entry?.isSmsDisabled || !update}
                                    />
                                  )}
                                  {entry.isSmsDisabled && (
                                    <div className="ml-2 mr-3">
                                      <MinusCircleIcon
                                        className="text-gray-300 w-7 h-7"
                                        aria-hidden="true"
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>

              <div className="flex flex-row justify-end py-4">
                {userId ? (
                  <>
                    {!update && (
                      <div className="flex flex-row justify-end w-full mt-4">
                        <Button
                          variant={ButtonVariant.Secondary}
                          size={ButtonSize.AppSize}
                          className=""
                          onClick={() => {
                            setUpdate(true);
                          }}
                          disabled={accountUsersLoading}
                        >
                          Update
                        </Button>
                      </div>
                    )}
                    {update && (
                      <div className="flex flex-row justify-end w-full mt-8">
                        <Button
                          variant={ButtonVariant.Red}
                          size={ButtonSize.AppSize}
                          className=""
                          onClick={() => {
                            setUpdate(false);
                            revertValues();
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant={ButtonVariant.Primary}
                          size={ButtonSize.AppSize}
                          className="ml-4"
                          disabled={isSubmitting}
                          loading={isSubmitting}
                          type={ButtonType.Submit}
                        >
                          Save
                        </Button>
                      </div>
                    )}
                  </>
                ) : (
                  <Button
                    variant={ButtonVariant.AppOrange}
                    size={ButtonSize.AppSize}
                    loading={isSubmitting}
                    disabled={isSubmitting}
                    type={ButtonType.Submit}
                    className="flex flex-row items-center w-full sm:w-auto"
                  >
                    Add New User
                  </Button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
