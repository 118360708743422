/** @format */
import { FeatureType, UserAuthType } from "@roadflex/types";
import classNames from "classnames";
import { NoAccessFeatureUI } from "../no-access";
import { DriverPerformance } from "./driver-performance/driver-performance";
import { FleetPerformance } from "./fleet-performance/fleet-performance";
import { TechnicalAnalysis } from "./technical-analysis/technical-analysis";

interface ReportsAdvancedUIProps {
  tabOpen: number;
  setTabOpen: (value: number) => void;
  childFeatures: FeatureType[];
  readCurrentUserAuth: UserAuthType;
}

export const ReportsAdvancedUI = ({
  tabOpen,
  setTabOpen,
  childFeatures,
  readCurrentUserAuth,
}: ReportsAdvancedUIProps) => {
  const tabs = [
    // Temporarily enabling only Driver performance
    { id: 1, name: "Fleet Performance", href: "", current: false },
    { id: 2, name: "Driver Performance", href: "", current: false },
    { id: 3, name: "Technical Analysis", href: "", current: false },
  ];
  const FleetPerformanceAccess =
    childFeatures.find((item) => item.name === "FLEET_PERFORMANCE_ANALYSIS") !==
    undefined;
  const DriverPerformanceAccess =
    childFeatures.find(
      (item) => item.name === "DRIVER_PERFORMANCE_ANALYSIS",
    ) !== undefined;
  const TechnicalAnalysisAccess =
    childFeatures.find((item) => item.name === "TECHNICAL_ANALYSIS") !==
    undefined;

  return (
    <div className="flex-col flex flex-1 m-4 mt-0 overflow-x-hidden md:px-4 md:pb-4 h-full md:min-h-[calc(100vh-96px)]">
      <div className="text-xl font-bold md:my-12 text-brown-500 md:text-2xl">
        {`Advanced Reporting`.toUpperCase()}
      </div>
      <div>
        <div className="mt-4 text-sm md:text-base md:mt-0">
          <div className="border-b border-brown-500">
            <nav className="flex -mb-px space-x-8 overflow-auto">
              {tabs.map((tab) => (
                <a
                  key={tab.name}
                  href={tab.href}
                  className={classNames(
                    tabOpen === tab.id
                      ? "border-black text-black"
                      : "border-transparent hover:border-black",
                    "whitespace-nowrap py-2 px-1 border-b-2 font-medium text-sm cursor-pointer",
                  )}
                  onClick={(e) => {
                    e.preventDefault();
                    setTabOpen(tab.id);
                  }}
                >
                  {(tab.name === "Fleet Performance" ||
                    tab.name === "Driver Performance" ||
                    tab.name === "Technical Analysis") && (
                    <>
                      {tab.name}{" "}
                      <span className="text-yellow-600">(Enterprise)</span>
                    </>
                  )}
                </a>
              ))}
            </nav>
          </div>
        </div>
      </div>
      <div className={`${tabOpen === 1 ? `` : `hidden`} mt-4 md:mt-6`}>
        {FleetPerformanceAccess && (
          <FleetPerformance {...{ readCurrentUserAuth }}></FleetPerformance>
        )}
        {!FleetPerformanceAccess && <NoAccessFeatureUI />}
      </div>
      <div className={`${tabOpen === 2 ? `` : `hidden`} mt-4 md:mt-6`}>
        {DriverPerformanceAccess && (
          <DriverPerformance {...{ readCurrentUserAuth }}></DriverPerformance>
        )}
        {!DriverPerformanceAccess && <NoAccessFeatureUI />}
      </div>
      <div className={`${tabOpen === 3 ? `` : `hidden`} mt-4 md:mt-6`}>
        {TechnicalAnalysisAccess && (
          <TechnicalAnalysis {...{ readCurrentUserAuth }}></TechnicalAnalysis>
        )}
        {!TechnicalAnalysisAccess && <NoAccessFeatureUI />}
      </div>
    </div>
  );
};
