/** @format */
import {
  BAR_GRAPH_CONFIG,
  REPORTS_PIE_GRAPH_CONFIG,
} from "@roadflex/constants";
import { Card, UserAuthType } from "@roadflex/types";
import {
  appChartsGraphSorter,
  getStartOfWeek,
  stringSorter,
} from "@roadflex/utils";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import { Calendar, CalendarChangeParams } from "primereact/calendar";
import { DropdownChangeParams } from "primereact/dropdown";
import { Select } from "../../../dropdowns";
import { InlineLoader } from "../../../loader";

type DisplayMode = {
  label: string;
  value: string;
};

interface CardAnalysisProps {
  cardAnalysisDataLoading: boolean;
  merchantByDollarSpentData: {
    name: string;
    y: number;
    sliced: boolean;
    selected: boolean;
  }[];
  merchantByTransactionCountData: {
    name: string;
    y: number;
    sliced: boolean;
    selected: boolean;
  }[];
  dates: Date[] | Date | undefined;
  setDates: (value: Date[]) => void;
  onDateRangeChange: (e: CalendarChangeParams) => void;
  onCardChange: (e: DropdownChangeParams) => void;
  cardId: string;
  cardList: Card[];
  gmvGraphData: Array<[string, string]>;
  transactionCountGraphData: Array<[string, string]>;
  startDate: Date;
  endDate: Date;
  displayModes: DisplayMode[];
  selectedDisplayMode: string;
  onDisplayModeChange: (e: DropdownChangeParams) => void;
  readCurrentUserAuth: UserAuthType;
}

const CardAnalysisUI = ({
  cardAnalysisDataLoading,
  merchantByDollarSpentData,
  merchantByTransactionCountData,
  dates,
  setDates,
  onDateRangeChange,
  onCardChange,
  cardId,
  cardList,
  gmvGraphData,
  transactionCountGraphData,
  startDate,
  endDate,
  displayModes,
  selectedDisplayMode,
  onDisplayModeChange,
  readCurrentUserAuth,
}: CardAnalysisProps) => {
  const graphOfGmvData = [];
  const gmvWeeklyDataDict: Record<string, number> = {};

  for (let i = 0; i < gmvGraphData.length || 0; i++) {
    const parsedDate = parseInt(gmvGraphData[i][0], 10);
    const parsedAmount = parseFloat(gmvGraphData[i][1]) / 100;
    graphOfGmvData.push([parsedDate, parsedAmount]);
    const convertedDate = new Date(parsedDate);
    const firstDayOfWeek = getStartOfWeek(convertedDate).getTime();
    if (firstDayOfWeek in gmvWeeklyDataDict) {
      gmvWeeklyDataDict[firstDayOfWeek] += parsedAmount;
    } else {
      gmvWeeklyDataDict[firstDayOfWeek] = parsedAmount;
    }
  }

  const graphOfGmvWeeklyData = Object.keys(gmvWeeklyDataDict)
    .sort(stringSorter)
    .map((weekKey) => {
      const weekInfo = gmvWeeklyDataDict[weekKey];
      return [parseInt(weekKey, 10), weekInfo];
    });

  const graphOfTransactionsData = [];
  const transactionsWeeklyDataDict: Record<string, number> = {};

  for (let i = 0; i < transactionCountGraphData?.length || 0; i++) {
    const parsedDate = parseInt(transactionCountGraphData[i][0], 10);
    const parsedNumberOfTransactions = parseInt(
      transactionCountGraphData[i][1],
      10,
    );
    graphOfTransactionsData.push([parsedDate, parsedNumberOfTransactions]);
    const convertedDate = new Date(parsedDate);
    const firstDayOfWeek = getStartOfWeek(convertedDate).getTime();
    if (firstDayOfWeek in transactionsWeeklyDataDict) {
      transactionsWeeklyDataDict[firstDayOfWeek] += parsedNumberOfTransactions;
    } else {
      transactionsWeeklyDataDict[firstDayOfWeek] = parsedNumberOfTransactions;
    }
  }

  const graphOfTransactionsWeeklyData = Object.keys(transactionsWeeklyDataDict)
    .sort(stringSorter)
    .map((weekKey) => {
      const weekInfo = transactionsWeeklyDataDict[weekKey];
      return [parseInt(weekKey, 10), weekInfo];
    });

  graphOfGmvData.sort(appChartsGraphSorter);
  graphOfTransactionsData.sort(appChartsGraphSorter);

  const finalGraphOfGmvData =
    selectedDisplayMode === "day" ? graphOfGmvData : graphOfGmvWeeklyData;

  const graphOfGmvOptions = {
    ...BAR_GRAPH_CONFIG,
    title: {
      text: "",
    },
    yAxis: [
      {
        title: {
          text: "Amount of GMV($)",
        },
      },
      {
        title: {
          text: "Amount of GMV($)",
        },
        linkedTo: 0,
        opposite: true,
      },
    ],
    colors: ["#bf3d00", "#1D1D20", "#a19d93"],
    tooltip: {
      valuePrefix: "$",
      crosshairs: true,
      shared: true,
      pointFormat: "${point.y:.2f}",
    },
    series: [
      {
        name: "Transaction Volume",
        data: finalGraphOfGmvData,
      },
    ],
  };

  const finalGraphOfTransactionsData =
    selectedDisplayMode === "day"
      ? graphOfTransactionsData
      : graphOfTransactionsWeeklyData;

  const graphOfTransactionsOptions = {
    ...BAR_GRAPH_CONFIG,
    title: {
      text: "",
    },
    yAxis: [
      {
        title: {
          text: "Number of Transactions",
        },
      },
      {
        title: {
          text: "Number of Transactions",
        },
        linkedTo: 0,
        opposite: true,
      },
    ],
    colors: ["#bf3d00", "#1D1D20", "#a19d93"],
    series: [
      {
        name: "Transactions",
        data: finalGraphOfTransactionsData,
      },
    ],
  };

  const merchantByDollarSpentBreakdown = {
    ...REPORTS_PIE_GRAPH_CONFIG,
    title: {
      text: "",
    },
    colors: ["#bf3d00", "#1D1D20", "#a19d93"],
    tooltip: {
      valueSuffix: "%",
      crosshairs: true,
      shared: true,
    },
    series: [
      {
        name: "Merchant breakdown (amount)",
        data: merchantByDollarSpentData,
      },
    ],
  };

  const merchantByTransactionCountBreakdown = {
    ...REPORTS_PIE_GRAPH_CONFIG,
    title: {
      text: "",
    },
    colors: ["#bf3d00", "#1D1D20", "#a19d93"],
    tooltip: {
      valueSuffix: "%",
      crosshairs: true,
      shared: true,
    },
    series: [
      {
        name: "Merchant breakdown (transaction count)",
        data: merchantByTransactionCountData,
      },
    ],
  };

  const NoDataToShowMessage = () => {
    return (
      <div className="absolute inset-0 flex items-center justify-center w-full h-full bg-white bg-opacity-70">
        <div className="p-6 text-base font-semibold bg-white border-2 rounded-md border-brown-500 text-brown-500 md:text-lg">
          No Data to Show
        </div>
      </div>
    );
  };

  const currentDate = new Date();
  const threeMonthsAgo = new Date();
  threeMonthsAgo.setMonth(currentDate.getMonth() - 3);

  return (
    <div className="grid grid-cols-2 gap-4 text-sm md:gap-3">
      <div className="col-span-2">
        <div className="flex flex-col items-center justify-start gap-4 md:gap-10 md:flex-row">
          <div className="w-full md:w-fit">
            <div className="flex flex-row items-center">
              <label className="pr-2 font-semibold" htmlFor="display-mode">
                Display Mode
              </label>
              <Select
                id="display-mode"
                value={selectedDisplayMode}
                options={displayModes}
                optionLabel="label"
                optionValue="value"
                onChange={onDisplayModeChange}
                placeholder="Select Mode"
                itemTemplate={(option) => {
                  return (
                    <span className={`mode-${option.label}`}>
                      {option.label}
                    </span>
                  );
                }}
              ></Select>
            </div>
          </div>
          <div className="flex flex-row items-center">
            <label className="flex flex-col pr-2 font-semibold" htmlFor="range">
              <div>
                Date Range (
                {readCurrentUserAuth?.accountDisplaySetting?.timeZone})
              </div>
              <div className="italic font-normal">Max Range: 1 month</div>
            </label>
            <Calendar
              id="range"
              value={dates}
              onChange={onDateRangeChange}
              selectionMode="range"
              placeholder="Select Date Range"
              className="custom-calendar"
              panelClassName="custom-calendar-panel"
              minDate={threeMonthsAgo}
              maxDate={currentDate}
              inputClassName="!py-2"
              readOnlyInput
            />
          </div>
        </div>
        <div className="flex flex-col justify-start gap-4 mt-4 md:mt-8 md:gap-6 md:items-center md:flex-row">
          <div className="flex flex-row items-center">
            <label className="pr-2 font-semibold" htmlFor="cardId">
              Card
            </label>
            <Select
              id="cardId"
              value={cardId}
              options={cardList}
              optionLabel="lastFour"
              optionValue="id"
              style={{
                fontFamily: "Inter",
                fontSize: "0.75rem",
              }}
              onChange={onCardChange}
              placeholder="Select card"
              itemTemplate={(option) => {
                return (
                  <span className={`card-${option.lastFour}`}>
                    {option.lastFour}
                  </span>
                );
              }}
            ></Select>
            {cardAnalysisDataLoading && <InlineLoader />}
          </div>
        </div>
      </div>
      <div className="col-span-2 p-4 bg-white rounded-md shadow md:mt-8 md:col-span-1 ">
        <label className="py-2 text-base font-semibold text-brown-500 md:text-lg">
          Transaction Volume each {selectedDisplayMode}
        </label>
        <div className="relative">
          <HighchartsReact
            highcharts={Highcharts}
            options={graphOfGmvOptions}
          />
          {finalGraphOfGmvData.length > 0 ? null : <NoDataToShowMessage />}
        </div>
      </div>
      <div className="col-span-2 p-4 bg-white rounded-md shadow md:mt-8 md:col-span-1 ">
        <label className="py-2 text-base font-semibold text-brown-500 md:text-lg">
          Number of Transactions each {selectedDisplayMode}
        </label>
        <div className="relative">
          <HighchartsReact
            highcharts={Highcharts}
            options={graphOfTransactionsOptions}
          />
          {finalGraphOfTransactionsData.length > 0 ? null : (
            <NoDataToShowMessage />
          )}
        </div>
      </div>
      <div className="col-span-2 p-4 bg-white rounded-md shadow md:mt-8 md:col-span-1 ">
        <label className="py-2 text-base font-semibold text-brown-500 md:text-lg">
          Merchant Breakdown (amount)
        </label>
        <div className="relative">
          <HighchartsReact
            highcharts={Highcharts}
            options={merchantByDollarSpentBreakdown}
          />
          {merchantByDollarSpentData.some(
            (merchantData) => merchantData.y !== 0,
          ) ? null : (
            <NoDataToShowMessage />
          )}
        </div>
      </div>
      <div className="col-span-2 p-4 bg-white rounded-md shadow md:mt-8 md:col-span-1 ">
        <label className="py-2 text-base font-semibold text-brown-500 md:text-lg">
          Merchant Breakdown (transaction count)
        </label>
        <div className="relative">
          <HighchartsReact
            highcharts={Highcharts}
            options={merchantByTransactionCountBreakdown}
          />
          {merchantByTransactionCountData.some(
            (merchantData) => merchantData.y !== 0,
          ) ? null : (
            <NoDataToShowMessage />
          )}
        </div>
      </div>
    </div>
  );
};

export default CardAnalysisUI;
