/* eslint-disable max-lines-per-function */
/** @format */
import { CheckCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { ENUM_CLASSIFICATION } from "@prisma/client";
import { CATEGORY_LABELS, dollarFormatter } from "@roadflex/constants";
import { usePlatformDetect } from "@roadflex/react-hooks";
import { Card, TechnicalAnalysisDataDriverVehicleType } from "@roadflex/types";
import { humanize } from "@roadflex/utils";
import { Column } from "primereact/column";
import { DataTable, DataTableRowToggleParams } from "primereact/datatable";
import { useState } from "react";
import { Button, ButtonSize, ButtonVariant } from "../../../buttons";
import { newPaginationTemplate } from "../../../datatable/pagination-templates/new-pagination-template";
import { CustomMultiSelect, Select } from "../../../dropdowns";
import { InlineLoader } from "../../../loader";
import { rowExpansionTemplateCards } from "./row-expansion-template-cards";
import { rowExpansionTemplateDrivers } from "./row-expansion-template-drivers";
import { rowExpansionTemplateVehicles } from "./row-expansion-template-vehicles";

type TechnicalAnalysisUIProps = {
  loading: boolean;
  queryTable: string;
  setQueryTable: (queryTable: string) => void;
  cards: Card[];
  cardsFilters: {
    operationalHours: string[];
    cardAssignments: string[];
    transactionControls: string[];
    spendingLimits: string[];
    merchantCategories: string[];
  };
  setCardsFilters: (cardsFilters: {
    operationalHours: string[];
    cardAssignments: string[];
    transactionControls: string[];
    spendingLimits: string[];
    merchantCategories: string[];
  }) => void;
  drivers: TechnicalAnalysisDataDriverVehicleType[];
  driversFilters: {
    assignments: string[];
    spend: string[];
    discount: string[];
    fuel: string[];
  };
  setDriversFilters: (driversFilters: {
    assignments: string[];
    spend: string[];
    discount: string[];
    fuel: string[];
  }) => void;
  vehicles: TechnicalAnalysisDataDriverVehicleType[];
  vehiclesFilters: {
    assignments: string[];
    spend: string[];
    discount: string[];
    fuel: string[];
  };
  setVehiclesFilters: (vehiclesFilters: {
    assignments: string[];
    spend: string[];
    discount: string[];
    fuel: string[];
  }) => void;
  resetFilters: () => void;
};

const TechnicalAnalysisUI = ({
  loading,
  queryTable,
  setQueryTable,
  cards,
  cardsFilters,
  setCardsFilters,
  drivers,
  driversFilters,
  setDriversFilters,
  vehicles,
  vehiclesFilters,
  setVehiclesFilters,
  resetFilters,
}: TechnicalAnalysisUIProps) => {
  const { isMobile } = usePlatformDetect();

  // Track row expansions for each table
  const [expandedRowsCards, setExpandedRowsCards] = useState<Card[]>([]);
  const [expandedRowsDrivers, setExpandedRowsDrivers] = useState<
    TechnicalAnalysisDataDriverVehicleType[]
  >([]);
  const [expandedRowsVehicles, setExpandedRowsVehicles] = useState<
    TechnicalAnalysisDataDriverVehicleType[]
  >([]);

  const statusElement = (status: boolean) => {
    return (
      <span>
        {status ? (
          <CheckCircleIcon
            className="inline w-10 h-5 text-green-800"
            aria-hidden="true"
          />
        ) : (
          <XMarkIcon
            className="inline w-10 h-5 text-brown-500"
            aria-hidden="true"
          />
        )}
      </span>
    );
  };

  return (
    <div className="flex-col flex flex-1 my-4 mt-0 overflow-x-hidden md:pb-4 h-full md:min-h-[calc(100vh-96px)]">
      <div className="col-span-2 mb-4">
        <div className="flex flex-col items-center justify-start gap-4 md:gap-10 md:flex-row">
          <div className="w-full md:w-fit">
            <div className="flex flex-row items-center">
              <label className="pr-2 font-semibold" htmlFor="queryTable">
                Query Table
              </label>
              <Select
                id="queryTable"
                value={queryTable}
                options={[
                  { value: "CARDS", label: "Cards" },
                  { value: "DRIVERS", label: "Drivers" },
                  { value: "VEHICLES", label: "Vehicles" },
                ]}
                optionLabel="label"
                optionValue="value"
                onChange={(e) => {
                  setQueryTable(e.value);
                }}
                placeholder="Select Query Type"
                className="w-52"
                itemTemplate={(option) => {
                  return (
                    <span className={`mode-${option.value}`}>
                      {option.label}
                    </span>
                  );
                }}
              ></Select>
              {loading && <InlineLoader />}
            </div>
          </div>
        </div>
      </div>
      {queryTable === "CARDS" ? (
        <div className="overflow-hidden bg-white rounded-md shadow md:mt-2">
          <div className="p-4 font-medium bg-white md:px-8 md:pb-8 step-5">
            <div className="flex items-center justify-between w-full mb-4 md:my-4">
              <div className="text-base font-semibold">Columns To Show</div>
              <div className="">
                <Button
                  size={ButtonSize.AppSize}
                  variant={ButtonVariant.AppOrange}
                  onClick={() => {
                    resetFilters();
                  }}
                >
                  Reset
                </Button>
              </div>
            </div>
            <div className="flex flex-wrap items-end w-full gap-4 my-4">
              <div className="md:mb-2 min-w-[200px] max-w-[250px]">
                <div className="mb-1 text-sm">Operational Hours</div>
                <CustomMultiSelect
                  value={cardsFilters.operationalHours}
                  options={[
                    {
                      label: "Status & Hours",
                      value: "operationalHours",
                    },
                  ]}
                  placeholder="Select Options"
                  maxSelectedLabels={2}
                  className="w-full !text-sm h-10 flex items-center"
                  onChange={(e) =>
                    setCardsFilters({
                      ...cardsFilters,
                      operationalHours: e.value,
                    })
                  }
                />
              </div>
              <div className="md:mb-2 min-w-[200px] max-w-[250px]">
                <div className="mb-1 text-sm">Card Assignments</div>
                <CustomMultiSelect
                  value={cardsFilters.cardAssignments}
                  options={[
                    {
                      label: "Driver",
                      value: "driver",
                    },
                    {
                      label: "Vehicle",
                      value: "vehicle",
                    },
                  ]}
                  placeholder="Select Options"
                  maxSelectedLabels={2}
                  className="w-full !text-sm h-10 flex items-center"
                  onChange={(e) =>
                    setCardsFilters({
                      ...cardsFilters,
                      cardAssignments: e.value,
                    })
                  }
                />
              </div>
              <div className="md:mb-2 min-w-[200px] max-w-[250px]">
                <div className="mb-1 text-sm">Transaction Controls</div>
                <CustomMultiSelect
                  value={cardsFilters.transactionControls}
                  options={[
                    {
                      label: "Card unlock through SMS or driver app",
                      value: "smsSecurity",
                    },
                    {
                      label:
                        "Require an odometer reading before every fuel transaction",
                      value: "odometerSecurity",
                    },
                    {
                      label: "Require a transaction tag ID",
                      value: "requireJobID",
                    },
                    {
                      label: "Enable receipt upload via SMS",
                      value: "enableSmsReceiptUpload",
                    },
                    {
                      label: "Flag for wrong fuel type",
                      value: "fuelTypeSecurity",
                    },
                    {
                      label: "Flag when fuel purchase exceeds tank capacity",
                      value: "tankCapacitySecurity",
                    },
                    {
                      label:
                        "Flag when fuel level anomaly is detected/fuel theft detected",
                      value: "suspiciousFuelingSecurity",
                    },
                    {
                      label:
                        "Flag transactions from specific merchant categories",
                      value: "flaggedMerchantCategories",
                    },
                    {
                      label:
                        "Alert/Decline transaction when vehicle is far away from the merchant",
                      value: "telematicsSecurity",
                    },
                  ]}
                  placeholder="Select Options"
                  maxSelectedLabels={1}
                  className="w-full !text-sm h-10 flex items-center"
                  onChange={(e) =>
                    setCardsFilters({
                      ...cardsFilters,
                      transactionControls: e.value,
                    })
                  }
                />
              </div>
              <div className="md:mb-2 min-w-[200px] max-w-[250px]">
                <div className="mb-1 text-sm">Spend Limits</div>
                <CustomMultiSelect
                  value={cardsFilters.spendingLimits}
                  options={[
                    {
                      label: "Per Transaction Limit",
                      value: "perTransactionSpendingLimit",
                    },
                    { label: "Daily Limit", value: "dailySpendingLimit" },
                    { label: "Weekly Limit", value: "weeklySpendingLimit" },
                    { label: "Monthly Limit", value: "monthlySpendingLimit" },
                  ]}
                  placeholder="Select Options"
                  maxSelectedLabels={2}
                  className="w-full !text-sm h-10 flex items-center"
                  onChange={(e) =>
                    setCardsFilters({
                      ...cardsFilters,
                      spendingLimits: e.value,
                    })
                  }
                />
              </div>
              <div className="md:mb-2 min-w-[200px] max-w-[250px]">
                <div className="mb-1 text-sm">Category Restrictions</div>
                <CustomMultiSelect
                  value={cardsFilters.merchantCategories}
                  options={[
                    {
                      label: "Fuel Expenses",
                      value: "fuelExpenses",
                    },
                    { label: "Vehicle Expenses", value: "vehicleExpenses" },
                    { label: "Business Expenses", value: "businessExpenses" },
                    {
                      label: "Additional Merchants",
                      value: "additionalMerchants",
                    },
                  ]}
                  placeholder="Select Options"
                  maxSelectedLabels={2}
                  className="w-full !text-sm h-10 flex items-center"
                  onChange={(e) =>
                    setCardsFilters({
                      ...cardsFilters,
                      merchantCategories: e.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="mx-auto">
              <div className="min-w-full overflow-hidden overflow-x-auto align-middle">
                <DataTable
                  pageLinkSize={isMobile() ? 2 : 3}
                  size="small"
                  stripedRows
                  value={cards}
                  loading={loading}
                  paginator
                  dataKey="id"
                  style={{
                    fontFamily: "Inter",
                  }}
                  paginatorTemplate={newPaginationTemplate}
                  responsiveLayout={isMobile() ? "scroll" : "scroll"}
                  emptyMessage="No available records"
                  className="!bg-white custom-table !text-brown-500 !border-brown-500"
                  sortMode={"single"}
                  rows={10}
                  sortField="lastFour"
                  sortOrder={1}
                  // currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                  rowExpansionTemplate={(e) =>
                    rowExpansionTemplateCards(e, cardsFilters)
                  }
                  expandedRows={expandedRowsCards}
                  onRowToggle={(e: DataTableRowToggleParams) =>
                    setExpandedRowsCards(e.data as Card[])
                  }
                >
                  <Column
                    field="lastFour"
                    header="Last Four & Status"
                    className="text-sm 5xl:text-base"
                    bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                    headerClassName="!bg-white !text-brown-500 !border-brown-500"
                    body={(r: Card) => {
                      return (
                        <div className="">
                          <div className="flex flex-row m-1 capitalize whitespace-nowrap text-ellipsis">
                            <span className="mr-1 font-semibold">
                              {r.lastFour}
                            </span>
                            ({r.cardType.toLowerCase()})
                            {statusElement(r?.status === "active")}
                          </div>
                        </div>
                      );
                    }}
                  />

                  {!isMobile() &&
                    cardsFilters?.operationalHours?.includes(
                      "operationalHours",
                    ) && (
                      <Column
                        header="Operational Hours"
                        className="text-sm 5xl:text-base"
                        bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                        headerClassName="!bg-white !text-brown-500 !border-brown-500"
                        body={(r: Card) => {
                          return (
                            <div className="flex flex-row">
                              <div className="my-1">
                                {statusElement(
                                  r?.enableOperationalHours || false,
                                )}
                              </div>
                              <div>
                                {r?.operationalHours?.map((op) => {
                                  return (
                                    <div
                                      className="m-1 text-sm whitespace-nowrap text-ellipsis"
                                      key={op.dayOfWeek}
                                    >
                                      <span className="font-semibold capitalize">
                                        {op.dayOfWeek.toLowerCase()}{" "}
                                      </span>
                                      ({op.startTime} - {op.endTime})
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          );
                        }}
                      />
                    )}
                  {!isMobile() &&
                    cardsFilters?.cardAssignments?.includes("driver") && (
                      <Column
                        header="Driver"
                        className="text-sm 5xl:text-base"
                        bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                        headerClassName="!bg-white !text-brown-500 !border-brown-500"
                        body={(r: Card) => {
                          return r?.fleetDriver[0]?.name || "-";
                        }}
                      />
                    )}
                  {!isMobile() &&
                    cardsFilters?.cardAssignments?.includes("vehicle") && (
                      <Column
                        header="Vehicle"
                        className="text-sm 5xl:text-base"
                        bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                        headerClassName="!bg-white !text-brown-500 !border-brown-500"
                        body={(r: Card) => {
                          return r?.fleet[0]?.fleetName || "-";
                        }}
                      />
                    )}
                  {!isMobile() &&
                    cardsFilters?.transactionControls?.includes(
                      "smsSecurity",
                    ) && (
                      <Column
                        header="Card unlock through SMS or driver app"
                        className="text-sm 5xl:text-base"
                        bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                        headerClassName="!bg-white !text-brown-500 !border-brown-500"
                        body={(r: Card) => {
                          return (
                            <div className="m-1">
                              {statusElement(r?.smsSecurity || false)}
                            </div>
                          );
                        }}
                      />
                    )}
                  {!isMobile() &&
                    cardsFilters?.transactionControls?.includes(
                      "odometerSecurity",
                    ) && (
                      <Column
                        header="Require an odometer reading before every fuel transaction"
                        className="text-sm 5xl:text-base"
                        bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                        headerClassName="!bg-white !text-brown-500 !border-brown-500"
                        body={(r: Card) => {
                          return (
                            <div className="m-1">
                              {statusElement(r?.odometerSecurity || false)}
                            </div>
                          );
                        }}
                      />
                    )}
                  {!isMobile() &&
                    cardsFilters?.transactionControls?.includes(
                      "requireJobID",
                    ) && (
                      <Column
                        header="Require a transaction tag ID"
                        className="text-sm 5xl:text-base"
                        bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                        headerClassName="!bg-white !text-brown-500 !border-brown-500"
                        body={(r: Card) => {
                          return (
                            <div className="m-1">
                              {statusElement(r?.requireJobID || false)}
                            </div>
                          );
                        }}
                      />
                    )}
                  {!isMobile() &&
                    cardsFilters?.transactionControls?.includes(
                      "enableSmsReceiptUpload",
                    ) && (
                      <Column
                        header="Enable receipt upload via SMS"
                        className="text-sm 5xl:text-base"
                        bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                        headerClassName="!bg-white !text-brown-500 !border-brown-500"
                        body={(r: Card) => {
                          return (
                            <div className="m-1">
                              {statusElement(
                                r?.enableSmsReceiptUpload || false,
                              )}
                            </div>
                          );
                        }}
                      />
                    )}
                  {!isMobile() &&
                    cardsFilters?.transactionControls?.includes(
                      "fuelTypeSecurity",
                    ) && (
                      <Column
                        header="Flag for wrong fuel type"
                        className="text-sm 5xl:text-base"
                        bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                        headerClassName="!bg-white !text-brown-500 !border-brown-500"
                        body={(r: Card) => {
                          return (
                            <div className="m-1">
                              {statusElement(r?.fuelTypeSecurity || false)}
                            </div>
                          );
                        }}
                      />
                    )}
                  {!isMobile() &&
                    cardsFilters?.transactionControls?.includes(
                      "tankCapacitySecurity",
                    ) && (
                      <Column
                        header="Flag when fuel purchase exceeds tank capacity"
                        className="text-sm 5xl:text-base"
                        bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                        headerClassName="!bg-white !text-brown-500 !border-brown-500"
                        body={(r: Card) => {
                          return (
                            <div className="m-1">
                              {statusElement(r?.tankCapacitySecurity || false)}
                            </div>
                          );
                        }}
                      />
                    )}
                  {!isMobile() &&
                    cardsFilters?.transactionControls?.includes(
                      "suspiciousFuelingSecurity",
                    ) && (
                      <Column
                        header="Flag when fuel level anomaly is detected/fuel theft detected"
                        className="text-sm 5xl:text-base"
                        bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                        headerClassName="!bg-white !text-brown-500 !border-brown-500"
                        body={(r: Card) => {
                          return (
                            <div className="m-1">
                              {statusElement(
                                r?.suspiciousFuelingSecurity || false,
                              )}
                            </div>
                          );
                        }}
                      />
                    )}
                  {!isMobile() &&
                    cardsFilters?.transactionControls?.includes(
                      "flaggedMerchantCategories",
                    ) && (
                      <Column
                        header="Flag transactions from specific merchant categories"
                        className="text-sm 5xl:text-base"
                        bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                        headerClassName="!bg-white !text-brown-500 !border-brown-500"
                        body={(r: Card) => {
                          return r.flaggedMerchantCategories?.map(
                            (merchant, index: number) => {
                              return (
                                <div
                                  className="m-1 whitespace-nowrap text-ellipsis"
                                  key={index}
                                >
                                  {(CATEGORY_LABELS[merchant] &&
                                    CATEGORY_LABELS[merchant]?.label) ||
                                    humanize(merchant)}
                                </div>
                              );
                            },
                          );
                        }}
                      />
                    )}
                  {!isMobile() &&
                    cardsFilters?.transactionControls?.includes(
                      "telematicsSecurity",
                    ) && (
                      <Column
                        header="Alert/Decline transaction when vehicle is far away from the merchant"
                        className="text-sm 5xl:text-base"
                        bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                        headerClassName="!bg-white !text-brown-500 !border-brown-500"
                        body={(r: Card) => {
                          return (
                            <div className="m-1 text-sm capitalize">
                              {r?.telematicsSecurity?.toLowerCase()}
                            </div>
                          );
                        }}
                      />
                    )}
                  {!isMobile() &&
                    cardsFilters?.spendingLimits?.includes(
                      "perTransactionSpendingLimit",
                    ) && (
                      <Column
                        header="Per Transaction Limit"
                        className="text-sm 5xl:text-base"
                        bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                        headerClassName="!bg-white !text-brown-500 !border-brown-500"
                        body={(r: Card) => {
                          const result = r.cardSpending.find(
                            (data) => data.interval === "per_authorization",
                          );
                          return (
                            <div className="text-sm">
                              {result?.amount
                                ? dollarFormatter(result.amount / 100)
                                : "-"}
                            </div>
                          );
                        }}
                      />
                    )}
                  {!isMobile() &&
                    cardsFilters?.spendingLimits?.includes(
                      "dailySpendingLimit",
                    ) && (
                      <Column
                        header="Daily Transaction Limit"
                        className="text-sm 5xl:text-base"
                        bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                        headerClassName="!bg-white !text-brown-500 !border-brown-500"
                        body={(r: Card) => {
                          const result = r.cardSpending.find(
                            (data) => data.interval === "daily",
                          );
                          return (
                            <div className="text-sm">
                              {result?.amount
                                ? dollarFormatter(result.amount / 100)
                                : "-"}
                            </div>
                          );
                        }}
                      />
                    )}
                  {!isMobile() &&
                    cardsFilters?.spendingLimits?.includes(
                      "weeklySpendingLimit",
                    ) && (
                      <Column
                        header="Weekly Transaction Limit"
                        className="text-sm 5xl:text-base"
                        bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                        headerClassName="!bg-white !text-brown-500 !border-brown-500"
                        body={(r: Card) => {
                          const result = r.cardSpending.find(
                            (data) => data.interval === "weekly",
                          );
                          return (
                            <div className="text-sm">
                              {result?.amount
                                ? dollarFormatter(result.amount / 100)
                                : "-"}
                            </div>
                          );
                        }}
                      />
                    )}
                  {!isMobile() &&
                    cardsFilters?.spendingLimits?.includes(
                      "monthlySpendingLimit",
                    ) && (
                      <Column
                        header="Monthly Transaction Limit"
                        className="text-sm 5xl:text-base"
                        bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                        headerClassName="!bg-white !text-brown-500 !border-brown-500"
                        body={(r: Card) => {
                          const result = r.cardSpending.find(
                            (data) => data.interval === "monthly",
                          );
                          return (
                            <div className="text-sm">
                              {result?.amount
                                ? dollarFormatter(result.amount / 100)
                                : "-"}
                            </div>
                          );
                        }}
                      />
                    )}
                  {!isMobile() &&
                    cardsFilters?.merchantCategories?.includes(
                      "fuelExpenses",
                    ) && (
                      <Column
                        header="Fuel Expenses"
                        className="text-sm 5xl:text-base"
                        bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                        headerClassName="!bg-white !text-brown-500 !border-brown-500"
                        body={(r: Card) => {
                          return r.merchants
                            ?.filter(
                              (merchant) =>
                                merchant.toggleOn &&
                                merchant.classification ===
                                  ENUM_CLASSIFICATION.FUEL,
                            )
                            .map((merchant, index: number) => {
                              return (
                                <div
                                  className="m-1 text-sm whitespace-nowrap text-ellipsis"
                                  key={index}
                                >
                                  {(CATEGORY_LABELS[merchant.name] &&
                                    CATEGORY_LABELS[merchant.name]?.label) ||
                                    humanize(merchant.name)}
                                </div>
                              );
                            });
                        }}
                      />
                    )}
                  {!isMobile() &&
                    cardsFilters?.merchantCategories?.includes(
                      "vehicleExpenses",
                    ) && (
                      <Column
                        header="Vehicle Expenses"
                        className="text-sm 5xl:text-base"
                        bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                        headerClassName="!bg-white !text-brown-500 !border-brown-500"
                        body={(r: Card) => {
                          return r.merchants
                            ?.filter(
                              (merchant) =>
                                merchant.toggleOn &&
                                merchant.classification ===
                                  ENUM_CLASSIFICATION.VEHICLE,
                            )
                            .map((merchant, index: number) => {
                              return (
                                <div
                                  className="m-1 text-sm whitespace-nowrap text-ellipsis"
                                  key={index}
                                >
                                  {(CATEGORY_LABELS[merchant.name] &&
                                    CATEGORY_LABELS[merchant.name]?.label) ||
                                    humanize(merchant.name)}
                                </div>
                              );
                            });
                        }}
                      />
                    )}
                  {!isMobile() &&
                    cardsFilters?.merchantCategories?.includes(
                      "businessExpenses",
                    ) && (
                      <Column
                        header="Business Expenses"
                        className="text-sm 5xl:text-base"
                        bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                        headerClassName="!bg-white !text-brown-500 !border-brown-500"
                        body={(r: Card) => {
                          return r.merchants
                            ?.filter(
                              (merchant) =>
                                merchant.toggleOn &&
                                merchant.classification ===
                                  ENUM_CLASSIFICATION.BUSINESS,
                            )
                            .map((merchant, index: number) => {
                              return (
                                <div
                                  className="m-1 text-sm whitespace-nowrap text-ellipsis"
                                  key={index}
                                >
                                  {(CATEGORY_LABELS[merchant.name] &&
                                    CATEGORY_LABELS[merchant.name]?.label) ||
                                    humanize(merchant.name)}
                                </div>
                              );
                            });
                        }}
                      />
                    )}
                  {!isMobile() &&
                    cardsFilters?.merchantCategories?.includes(
                      "additionalMerchants",
                    ) && (
                      <Column
                        header="Additional Merchants"
                        className="text-sm 5xl:text-base"
                        bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                        headerClassName="!bg-white !text-brown-500 !border-brown-500"
                        body={(r: Card) => {
                          return r.merchants
                            ?.filter(
                              (merchant) =>
                                merchant.toggleOn &&
                                merchant.classification ===
                                  ENUM_CLASSIFICATION.OTHER,
                            )
                            .map((merchant, index: number) => {
                              return (
                                <div
                                  className="m-1 text-sm whitespace-nowrap text-ellipsis"
                                  key={index}
                                >
                                  {(CATEGORY_LABELS[merchant.name] &&
                                    CATEGORY_LABELS[merchant.name]?.label) ||
                                    humanize(merchant.name)}
                                </div>
                              );
                            });
                        }}
                      />
                    )}

                  {isMobile() && (
                    <Column
                      expander
                      style={{ width: "3em" }}
                      headerClassName="!bg-white !text-brown-500 !border-brown-500"
                    />
                  )}
                </DataTable>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {queryTable === "DRIVERS" ? (
        <div className="overflow-hidden bg-white rounded-md shadow md:mt-2">
          <div className="p-4 font-medium bg-white md:px-8 md:pb-8 step-5">
            <div className="flex items-center justify-between w-full mb-4 md:my-4">
              <div className="text-base font-semibold">Columns To Show</div>
              <div className="">
                <Button
                  size={ButtonSize.AppSize}
                  variant={ButtonVariant.AppOrange}
                  onClick={() => {
                    resetFilters();
                  }}
                >
                  Reset
                </Button>
              </div>
            </div>
            <div className="flex flex-wrap items-end w-full gap-4 my-4">
              <div className="md:mb-2 min-w-[200px] max-w-[250px]">
                <div className="mb-1 text-sm">Assignments</div>
                <CustomMultiSelect
                  value={driversFilters.assignments}
                  options={[
                    {
                      label: "Card",
                      value: "card",
                    },
                    {
                      label: "Vehicle",
                      value: "vehicle",
                    },
                  ]}
                  placeholder="Select Options"
                  maxSelectedLabels={2}
                  className="w-full !text-sm h-10 flex items-center"
                  onChange={(e) =>
                    setDriversFilters({
                      ...driversFilters,
                      assignments: e.value,
                    })
                  }
                />
              </div>
              <div className="md:mb-2 min-w-[200px] max-w-[250px]">
                <div className="mb-1 text-sm">Fuel</div>
                <CustomMultiSelect
                  value={driversFilters.fuel}
                  options={[
                    {
                      label: "Fuel (gal)",
                      value: "fuel",
                    },
                  ]}
                  placeholder="Select Options"
                  maxSelectedLabels={1}
                  className="w-full !text-sm h-10 flex items-center"
                  onChange={(e) =>
                    setDriversFilters({
                      ...driversFilters,
                      fuel: e.value,
                    })
                  }
                />
              </div>
              <div className="md:mb-2 min-w-[200px] max-w-[250px]">
                <div className="mb-1 text-sm">Spend</div>
                <CustomMultiSelect
                  value={driversFilters.spend}
                  options={[
                    {
                      label: "Spend past 30 Days",
                      value: "spend30Days",
                    },
                    {
                      label: "Spend past 60 Days",
                      value: "spend60Days",
                    },
                    {
                      label: "Spend past 90 Days",
                      value: "spend90Days",
                    },
                  ]}
                  placeholder="Select Options"
                  maxSelectedLabels={2}
                  className="w-full !text-sm h-10 flex items-center"
                  onChange={(e) =>
                    setDriversFilters({
                      ...driversFilters,
                      spend: e.value,
                    })
                  }
                />
              </div>
              <div className="md:mb-2 min-w-[200px] max-w-[250px]">
                <div className="mb-1 text-sm">Discount</div>
                <CustomMultiSelect
                  value={driversFilters.discount}
                  options={[
                    {
                      label: "Discount past 30 Days",
                      value: "discount30Days",
                    },
                    {
                      label: "Discount past 60 Days",
                      value: "discount60Days",
                    },
                    {
                      label: "Discount past 90 Days",
                      value: "discount90Days",
                    },
                  ]}
                  placeholder="Select Options"
                  maxSelectedLabels={2}
                  className="w-full !text-sm h-10 flex items-center"
                  onChange={(e) =>
                    setDriversFilters({
                      ...driversFilters,
                      discount: e.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="mx-auto">
              <div className="min-w-full overflow-hidden overflow-x-auto align-middle">
                <DataTable
                  pageLinkSize={isMobile() ? 2 : 3}
                  size="small"
                  stripedRows
                  value={drivers}
                  loading={loading}
                  paginator
                  dataKey="id"
                  style={{
                    fontFamily: "Inter",
                  }}
                  paginatorTemplate={newPaginationTemplate}
                  responsiveLayout={isMobile() ? "scroll" : "scroll"}
                  emptyMessage="No available records"
                  className="!bg-white custom-table !text-brown-500 !border-brown-500"
                  sortMode={"single"}
                  rows={10}
                  sortField="driverName"
                  sortOrder={1}
                  // currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                  rowExpansionTemplate={(rowData) =>
                    rowExpansionTemplateDrivers(rowData, driversFilters)
                  }
                  expandedRows={expandedRowsDrivers}
                  onRowToggle={(e) =>
                    setExpandedRowsDrivers(
                      e.data as TechnicalAnalysisDataDriverVehicleType[],
                    )
                  }
                >
                  <Column
                    sortable
                    field="driverName"
                    header="Driver Name"
                    className="text-sm 5xl:text-base"
                    bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                    headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  />
                  {!isMobile() &&
                    driversFilters?.assignments?.includes("card") && (
                      <Column
                        header="Card"
                        className="text-sm 5xl:text-base"
                        bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                        headerClassName="!bg-white !text-brown-500 !border-brown-500"
                        body={(r: TechnicalAnalysisDataDriverVehicleType) => {
                          return <div>{r?.cardNumber[0] || "-"}</div>;
                        }}
                      />
                    )}
                  {!isMobile() &&
                    driversFilters?.assignments?.includes("vehicle") && (
                      <Column
                        header="Vehicle"
                        className="text-sm 5xl:text-base"
                        bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                        headerClassName="!bg-white !text-brown-500 !border-brown-500"
                        body={(r: TechnicalAnalysisDataDriverVehicleType) => {
                          return <div>{r?.vehicleName[0] || "-"}</div>;
                        }}
                      />
                    )}
                  {!isMobile() && driversFilters?.fuel?.includes("fuel") && (
                    <Column
                      sortable
                      field="fuelQuantity"
                      header="Fuel (gal)"
                      className="text-sm 5xl:text-base"
                      bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                      headerClassName="!bg-white !text-brown-500 !border-brown-500"
                      body={(r: TechnicalAnalysisDataDriverVehicleType) => {
                        return Math.round(r.fuelQuantity * 100) / 100;
                      }}
                    />
                  )}
                  {!isMobile() &&
                    driversFilters?.spend?.includes("spend30Days") && (
                      <Column
                        sortable
                        field="spend30Days"
                        header="Spend past 30 Days"
                        className="text-sm 5xl:text-base"
                        bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                        headerClassName="!bg-white !text-brown-500 !border-brown-500"
                        body={(r: TechnicalAnalysisDataDriverVehicleType) =>
                          dollarFormatter(r.spend30Days / 100)
                        }
                      />
                    )}
                  {!isMobile() &&
                    driversFilters?.spend?.includes("spend60Days") && (
                      <Column
                        sortable
                        field="spend60Days"
                        header="Spend past 60 Days"
                        className="text-sm 5xl:text-base"
                        bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                        headerClassName="!bg-white !text-brown-500 !border-brown-500"
                        body={(r: TechnicalAnalysisDataDriverVehicleType) =>
                          dollarFormatter(r.spend60Days / 100)
                        }
                      />
                    )}
                  {!isMobile() &&
                    driversFilters?.spend?.includes("spend90Days") && (
                      <Column
                        sortable
                        field="spend90Days"
                        header="Spend past 90 Days"
                        className="text-sm 5xl:text-base"
                        bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                        headerClassName="!bg-white !text-brown-500 !border-brown-500"
                        body={(r: TechnicalAnalysisDataDriverVehicleType) =>
                          dollarFormatter(r.spend90Days / 100)
                        }
                      />
                    )}
                  {!isMobile() &&
                    driversFilters?.discount?.includes("discount30Days") && (
                      <Column
                        sortable
                        field="discount30Days"
                        header="Discount past 30 Days"
                        className="text-sm 5xl:text-base"
                        bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                        headerClassName="!bg-white !text-brown-500 !border-brown-500"
                        body={(r: TechnicalAnalysisDataDriverVehicleType) =>
                          dollarFormatter(r.discount30Days / 100)
                        }
                      />
                    )}
                  {!isMobile() &&
                    driversFilters?.discount?.includes("discount60Days") && (
                      <Column
                        sortable
                        field="discount60Days"
                        header="Discount past 60 Days"
                        className="text-sm 5xl:text-base"
                        bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                        headerClassName="!bg-white !text-brown-500 !border-brown-500"
                        body={(r: TechnicalAnalysisDataDriverVehicleType) =>
                          dollarFormatter(r.discount60Days / 100)
                        }
                      />
                    )}
                  {!isMobile() &&
                    driversFilters?.discount?.includes("discount90Days") && (
                      <Column
                        sortable
                        field="discount90Days"
                        header="Discount past 90 Days"
                        className="text-sm 5xl:text-base"
                        bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                        headerClassName="!bg-white !text-brown-500 !border-brown-500"
                        body={(r: TechnicalAnalysisDataDriverVehicleType) =>
                          dollarFormatter(r.discount90Days / 100)
                        }
                      />
                    )}
                  {isMobile() && (
                    <Column
                      expander
                      style={{ width: "3em" }}
                      headerClassName="!bg-white !text-brown-500 !border-brown-500"
                    />
                  )}
                </DataTable>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {queryTable === "VEHICLES" ? (
        <div className="overflow-hidden bg-white rounded-md shadow md:mt-2">
          <div className="p-4 font-medium bg-white md:px-8 md:pb-8 step-5">
            <div className="flex items-center justify-between w-full mb-4 md:my-4">
              <div className="text-base font-semibold">Columns To Show</div>
              <div className="">
                <Button
                  size={ButtonSize.AppSize}
                  variant={ButtonVariant.AppOrange}
                  onClick={() => {
                    resetFilters();
                  }}
                >
                  Reset
                </Button>
              </div>
            </div>
            <div className="flex flex-wrap items-end w-full gap-4 my-4">
              <div className="md:mb-2 min-w-[200px] max-w-[250px]">
                <div className="mb-1 text-sm">Assignments</div>
                <CustomMultiSelect
                  value={vehiclesFilters.assignments}
                  options={[
                    {
                      label: "Card",
                      value: "card",
                    },
                    {
                      label: "Driver",
                      value: "driver",
                    },
                  ]}
                  placeholder="Select Options"
                  maxSelectedLabels={2}
                  className="w-full !text-sm h-10 flex items-center"
                  onChange={(e) =>
                    setVehiclesFilters({
                      ...vehiclesFilters,
                      assignments: e.value,
                    })
                  }
                />
              </div>
              <div className="md:mb-2 min-w-[200px] max-w-[250px]">
                <div className="mb-1 text-sm">Fuel</div>
                <CustomMultiSelect
                  value={vehiclesFilters.fuel}
                  options={[
                    {
                      label: "Fuel (gal)",
                      value: "fuel",
                    },
                  ]}
                  placeholder="Select Options"
                  maxSelectedLabels={1}
                  className="w-full !text-sm h-10 flex items-center"
                  onChange={(e) =>
                    setVehiclesFilters({
                      ...vehiclesFilters,
                      fuel: e.value,
                    })
                  }
                />
              </div>
              <div className="md:mb-2 min-w-[200px] max-w-[250px]">
                <div className="mb-1 text-sm">Spend</div>
                <CustomMultiSelect
                  value={vehiclesFilters.spend}
                  options={[
                    {
                      label: "Spend past 30 Days",
                      value: "spend30Days",
                    },
                    {
                      label: "Spend past 60 Days",
                      value: "spend60Days",
                    },
                    {
                      label: "Spend past 90 Days",
                      value: "spend90Days",
                    },
                  ]}
                  placeholder="Select Options"
                  maxSelectedLabels={2}
                  className="w-full !text-sm h-10 flex items-center"
                  onChange={(e) =>
                    setVehiclesFilters({
                      ...vehiclesFilters,
                      spend: e.value,
                    })
                  }
                />
              </div>
              <div className="md:mb-2 min-w-[200px] max-w-[250px]">
                <div className="mb-1 text-sm">Discount</div>
                <CustomMultiSelect
                  value={vehiclesFilters.discount}
                  options={[
                    {
                      label: "Discount past 30 Days",
                      value: "discount30Days",
                    },
                    {
                      label: "Discount past 60 Days",
                      value: "discount60Days",
                    },
                    {
                      label: "Discount past 90 Days",
                      value: "discount90Days",
                    },
                  ]}
                  placeholder="Select Options"
                  maxSelectedLabels={2}
                  className="w-full !text-sm h-10 flex items-center"
                  onChange={(e) =>
                    setVehiclesFilters({
                      ...vehiclesFilters,
                      discount: e.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="mx-auto">
              <div className="min-w-full overflow-hidden overflow-x-auto align-middle">
                <DataTable
                  pageLinkSize={isMobile() ? 2 : 3}
                  size="small"
                  stripedRows
                  value={vehicles}
                  loading={loading}
                  paginator
                  dataKey="id"
                  style={{
                    fontFamily: "Inter",
                  }}
                  paginatorTemplate={newPaginationTemplate}
                  responsiveLayout={isMobile() ? "scroll" : "scroll"}
                  emptyMessage="No available records"
                  className="!bg-white custom-table !text-brown-500 !border-brown-500"
                  sortMode={"single"}
                  rows={10}
                  sortField="vehicleName"
                  sortOrder={1}
                  rowExpansionTemplate={(rowData) =>
                    rowExpansionTemplateVehicles(rowData, vehiclesFilters)
                  }
                  expandedRows={expandedRowsVehicles}
                  onRowToggle={(e) =>
                    setExpandedRowsVehicles(
                      e.data as TechnicalAnalysisDataDriverVehicleType[],
                    )
                  }
                  // currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                >
                  <Column
                    sortable
                    field="vehicleName"
                    header="Vehicle Name"
                    className="text-sm 5xl:text-base"
                    bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                    headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  />
                  {!isMobile() &&
                    vehiclesFilters?.assignments?.includes("card") && (
                      <Column
                        header="Card"
                        className="text-sm 5xl:text-base"
                        bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                        headerClassName="!bg-white !text-brown-500 !border-brown-500"
                        body={(r: TechnicalAnalysisDataDriverVehicleType) => {
                          return <div>{r?.cardNumber[0] || "-"}</div>;
                        }}
                      />
                    )}
                  {!isMobile() &&
                    vehiclesFilters?.assignments?.includes("driver") && (
                      <Column
                        header="Driver"
                        className="text-sm 5xl:text-base"
                        bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                        headerClassName="!bg-white !text-brown-500 !border-brown-500"
                        body={(r: TechnicalAnalysisDataDriverVehicleType) => {
                          return <div>{r?.driverName[0] || "-"}</div>;
                        }}
                      />
                    )}
                  {!isMobile() && vehiclesFilters?.fuel?.includes("fuel") && (
                    <Column
                      sortable
                      field="fuelQuantity"
                      header="Fuel (gal)"
                      className="text-sm 5xl:text-base"
                      bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                      headerClassName="!bg-white !text-brown-500 !border-brown-500"
                      body={(r: TechnicalAnalysisDataDriverVehicleType) => {
                        return Math.round(r.fuelQuantity * 100) / 100;
                      }}
                    />
                  )}
                  {!isMobile() &&
                    vehiclesFilters?.spend?.includes("spend30Days") && (
                      <Column
                        sortable
                        field="spend30Days"
                        header="Spend past 30 Days"
                        className="text-sm 5xl:text-base"
                        bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                        headerClassName="!bg-white !text-brown-500 !border-brown-500"
                        body={(r: TechnicalAnalysisDataDriverVehicleType) =>
                          dollarFormatter(r.spend30Days / 100)
                        }
                      />
                    )}
                  {!isMobile() &&
                    vehiclesFilters?.spend?.includes("spend60Days") && (
                      <Column
                        sortable
                        field="spend60Days"
                        header="Spend past 60 Days"
                        className="text-sm 5xl:text-base"
                        bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                        headerClassName="!bg-white !text-brown-500 !border-brown-500"
                        body={(r: TechnicalAnalysisDataDriverVehicleType) =>
                          dollarFormatter(r.spend60Days / 100)
                        }
                      />
                    )}
                  {!isMobile() &&
                    vehiclesFilters?.spend?.includes("spend90Days") && (
                      <Column
                        sortable
                        field="spend90Days"
                        header="Spend past 90 Days"
                        className="text-sm 5xl:text-base"
                        bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                        headerClassName="!bg-white !text-brown-500 !border-brown-500"
                        body={(r: TechnicalAnalysisDataDriverVehicleType) =>
                          dollarFormatter(r.spend90Days / 100)
                        }
                      />
                    )}
                  {!isMobile() &&
                    vehiclesFilters?.discount?.includes("discount30Days") && (
                      <Column
                        sortable
                        field="discount30Days"
                        header="Discount past 30 Days"
                        className="text-sm 5xl:text-base"
                        bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                        headerClassName="!bg-white !text-brown-500 !border-brown-500"
                        body={(r: TechnicalAnalysisDataDriverVehicleType) =>
                          dollarFormatter(r.discount30Days / 100)
                        }
                      />
                    )}
                  {!isMobile() &&
                    vehiclesFilters?.discount?.includes("discount60Days") && (
                      <Column
                        sortable
                        field="discount60Days"
                        header="Discount past 60 Days"
                        className="text-sm 5xl:text-base"
                        bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                        headerClassName="!bg-white !text-brown-500 !border-brown-500"
                        body={(r: TechnicalAnalysisDataDriverVehicleType) =>
                          dollarFormatter(r.discount60Days / 100)
                        }
                      />
                    )}
                  {!isMobile() &&
                    vehiclesFilters?.discount?.includes("discount90Days") && (
                      <Column
                        sortable
                        field="discount90Days"
                        header="Discount past 90 Days"
                        className="text-sm 5xl:text-base"
                        bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                        headerClassName="!bg-white !text-brown-500 !border-brown-500"
                        body={(r: TechnicalAnalysisDataDriverVehicleType) =>
                          dollarFormatter(r.discount90Days / 100)
                        }
                      />
                    )}
                  {isMobile() && (
                    <Column
                      expander
                      style={{ width: "3em" }}
                      headerClassName="!bg-white !text-brown-500 !border-brown-500"
                    />
                  )}
                </DataTable>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default TechnicalAnalysisUI;
