/** @format */

import { ENUM_CARD_ORDER_STATUS, ENUM_CARD_TYPE } from "@prisma/client";
import { useReadCardOrder, useUpdateCardOrder } from "@roadflex/react-hooks";
import { CardOrderType } from "@roadflex/types";
import { humanizeEnum } from "@roadflex/utils";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Button, ButtonSize, ButtonVariant } from "../../buttons";
import Toast from "../../toast-message/toast";

export const PlacedCardOrdersUI: React.FC = () => {
  // 1) Use the readCardOrder hook to fetch card orders from the server
  const {
    readCardOrder, // { code, message, data: CardOrder[] }
    loading: readOrderLoading,
    error: readOrderError,
    refetch: refetchCardOrders,
  } = useReadCardOrder();

  // 2) Use the updateCardOrder hook to cancel or delete an order
  const {
    updateCardOrder,
    loading: updateOrderLoading,
    error: updateOrderError,
  } = useUpdateCardOrder();

  // 3) Local state to hold orders from the server
  const [orders, setOrders] = useState<CardOrderType[]>([]);

  // 4) Whenever readCardOrder changes, populate local orders state
  useEffect(() => {
    if (readCardOrder && readCardOrder.data) {
      setOrders(readCardOrder.data);
    }
  }, [readCardOrder]);

  // 5) Convert server's cancellableUntil (or canCancelUntil) into a countdown
  const [timeLeftMap, setTimeLeftMap] = useState<{ [key: string]: number }>({});

  // Helper to get time difference in milliseconds
  const getDiffInMs = (order: CardOrderType) => {
    const now = new Date().getTime();
    const cancelDeadline = new Date(order.cancellableUntil).getTime();
    const diff = cancelDeadline - now;
    return diff > 0 ? diff : 0; // return 0 if time passed
  };

  // Helper to convert ms -> hh:mm:ss
  const msToHms = (ms: number) => {
    const totalSeconds = Math.floor(ms / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    const pad = (val: number) => (val < 10 ? `0${val}` : val);
    return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
  };

  // 6) Initialize & Update countdown every second
  useEffect(() => {
    // Initialize on mount
    setTimeLeftMap(() =>
      orders.reduce((acc, order) => {
        acc[order.id] = getDiffInMs(order);
        return acc;
      }, {} as { [key: string]: number }),
    );

    // Update every second
    const timer = setInterval(() => {
      setTimeLeftMap((prev) => {
        const updatedMap: { [key: string]: number } = {};
        orders.forEach((order) => {
          updatedMap[order.id] = getDiffInMs(order);
        });
        return updatedMap;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [orders]);

  // 6) Cancel order -> update local state if mutation succeeds
  const handleCancel = async (orderId: string) => {
    try {
      const result = await updateCardOrder({
        orderId,
        status: ENUM_CARD_ORDER_STATUS.CANCELLED,
      });

      if (result?.code === "200") {
        setOrders((prevOrders) =>
          prevOrders.map((o) =>
            o.id === orderId
              ? { ...o, status: ENUM_CARD_ORDER_STATUS.CANCELLED }
              : o,
          ),
        );
        Toast({
          type: "success",
          message: result.message,
        });
        return;
      }
      Toast({
        type: "error",
        message: result?.message || "Something went wrong.",
      });
    } catch (err) {
      Toast({
        type: "error",
        message: "Something went wrong.",
      });
    }
  };

  // 7) Delete order -> remove it from local state if mutation succeeds
  const handleDelete = async (orderId: string) => {
    try {
      const result = await updateCardOrder({
        orderId,
        status: ENUM_CARD_ORDER_STATUS.DELETED,
      });

      if (result?.code === "200") {
        // Filter the deleted order out of the local state
        setOrders((prevOrders) => prevOrders.filter((o) => o.id !== orderId));
      }
    } catch (err) {
      console.error("Delete failed", err);
    }
  };

  if (readOrderError && readOrderError?.message !== "Action is not permitted") {
    return <div className="text-red-500">Error: {readOrderError.message}</div>;
  }

  // 10) Hide entire UI if no card orders
  if (!orders.length) {
    return null;
  }

  // 11) Render the orders UI
  return (
    <div className="flex flex-col w-full p-4 mt-4 bg-white rounded-md shadow md:mt-8 md:p-8">
      <div className="py-2 mb-2 font-semibold text-brown-500">Card Orders</div>

      <div className="grid gap-6 sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
        {orders.map((order, index) => {
          const diffMs = timeLeftMap[order.id] ?? 0;
          const canCancel =
            order.status === ENUM_CARD_ORDER_STATUS.PLACED && diffMs > 0;

          return (
            <div
              key={order.id}
              className="relative flex flex-col p-5 bg-white border border-gray-300 rounded-md shadow-sm"
            >
              <div className="mb-4">
                <div className="flex items-center justify-between">
                  <span className="text-sm font-medium text-brown-500">
                    Order #{index + 1}
                  </span>
                  <span
                    className={classNames(
                      "text-xs font-semibold tracking-wider px-2 py-1 rounded-md",
                      {
                        "bg-green-100 text-green-800":
                          order.status === ENUM_CARD_ORDER_STATUS.PLACED,
                        "bg-blue-100 text-blue-800":
                          order.status === ENUM_CARD_ORDER_STATUS.FULFILLED,
                        "bg-red-100 text-red-800":
                          order.status === ENUM_CARD_ORDER_STATUS.CANCELLED ||
                          order.status === ENUM_CARD_ORDER_STATUS.FAILED,
                      },
                    )}
                  >
                    {humanizeEnum(order.status)}
                  </span>
                </div>
              </div>

              <div className="space-y-1 text-sm text-gray-700">
                <div className="flex items-center justify-between">
                  <span>Type</span>
                  <span className="font-medium capitalize">
                    {humanizeEnum(order.cardType)}
                  </span>
                </div>
                <div className="flex items-center justify-between">
                  <span>Quantity</span>
                  <span className="font-medium">{order.quantity}</span>
                </div>
                <div className="flex items-center justify-between">
                  <span>Delivery Type</span>
                  <span className="font-medium">
                    {humanizeEnum(order.deliveryType)}
                  </span>
                </div>

                <div className="flex items-center justify-between">
                  <span>Signature</span>
                  <span className="font-medium">
                    {order.requireSignature ? "Required" : "Not Required"}
                  </span>
                </div>
              </div>

              {/* Shipping Address */}
              {order.cardType === ENUM_CARD_TYPE.PHYSICAL && (
                <div className="pt-3 mt-3 text-sm text-gray-600 border-t">
                  <div className="mb-2 font-medium text-brown-500">
                    Shipping Address
                  </div>
                  <div>{order.streetAddress}</div>
                  {order.streetDetails && <div>{order.streetDetails}</div>}
                  <div>
                    {order.city}, {order.state} {order.zip}
                  </div>
                </div>
              )}

              {order.failedReason && (
                <div className="pt-3 mt-3 text-sm text-gray-600 border-t">
                  <div className="flex items-center justify-between">
                    <span>Reason</span>
                    <span className="font-medium ">
                      {humanizeEnum(order.failedReason)}
                    </span>
                  </div>
                </div>
              )}
              <div className="mb-3"></div>

              {/* Footer Buttons */}
              {canCancel && (
                <div className="mt-auto">
                  <div className="text-xs font-semibold text-center text-orange-500">
                    Cancel in {msToHms(diffMs)}
                  </div>
                  <Button
                    variant={ButtonVariant.AppOrange}
                    size={ButtonSize.Small}
                    onClick={() => handleCancel(order.id)}
                    className="w-full"
                    disabled={updateOrderLoading}
                  >
                    Cancel Order
                  </Button>
                </div>
              )}

              {(order.status === ENUM_CARD_ORDER_STATUS.CANCELLED ||
                order.status === ENUM_CARD_ORDER_STATUS.FAILED ||
                order.status === ENUM_CARD_ORDER_STATUS.FULFILLED) && (
                <div className="mt-auto">
                  <Button
                    variant={ButtonVariant.AppOrange}
                    size={ButtonSize.Small}
                    onClick={() => handleDelete(order.id)}
                    className="w-full"
                    disabled={updateOrderLoading}
                  >
                    Remove Order
                  </Button>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
