import { CheckCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { ENUM_CLASSIFICATION } from "@prisma/client";
import { CATEGORY_LABELS, dollarFormatter } from "@roadflex/constants";
import { Card } from "@roadflex/types";
import { humanize } from "@roadflex/utils";

// Simple helper to display a check or X
const statusElement = (status: boolean) => {
  return status ? (
    <CheckCircleIcon className="inline w-10 h-5 text-green-800" />
  ) : (
    <XMarkIcon className="inline w-10 h-5 text-brown-500" />
  );
};

type CardsFiltersType = {
  operationalHours: string[];
  cardAssignments: string[];
  transactionControls: string[];
  spendingLimits: string[];
  merchantCategories: string[];
};

/**
 * rowExpansionTemplateCards
 * Renders expanded details for each Card row, grouped by cardsFilters.
 */
export const rowExpansionTemplateCards = (
  rowData: Card,
  cardsFilters: CardsFiltersType,
) => {
  return (
    <div className="max-w-sm p-4 mx-auto text-sm bg-white border border-gray-300 rounded-md shadow-lg">
      {/* 1) OPERATIONAL HOURS */}
      {cardsFilters.operationalHours.includes("operationalHours") && (
        <section>
          <h5 className="mb-1 font-semibold">Operational Hours</h5>
          <div>
            <span>Enabled:</span>
            {statusElement(rowData.enableOperationalHours || false)}
          </div>
          {(rowData.operationalHours || [])?.length > 0 && (
            <ul className="mt-1 list-disc list-inside">
              {(rowData.operationalHours || []).map((op) => (
                <li key={op.dayOfWeek}>
                  {op.dayOfWeek} {op.startTime} - {op.endTime}
                </li>
              ))}
            </ul>
          )}
        </section>
      )}

      {/* 2) CARD ASSIGNMENTS */}
      {(cardsFilters.cardAssignments.includes("driver") ||
        cardsFilters.cardAssignments.includes("vehicle")) && (
        <section>
          <h5 className="mb-1 font-semibold">Assignments</h5>
          {cardsFilters.cardAssignments.includes("driver") && (
            <div className="mb-1">
              <strong>Driver:</strong> {rowData.fleetDriver?.[0]?.name || "-"}
            </div>
          )}
          {cardsFilters.cardAssignments.includes("vehicle") && (
            <div className="mb-1">
              <strong>Vehicle:</strong> {rowData.fleet?.[0]?.fleetName || "-"}
            </div>
          )}
        </section>
      )}

      {/* 3) TRANSACTION CONTROLS */}
      {cardsFilters.transactionControls.length > 0 && (
        <section>
          <h5 className="mb-1 font-semibold">Transaction Controls</h5>
          <ul className="space-y-1 list-disc list-inside">
            {cardsFilters.transactionControls.map((control) => {
              switch (control) {
                case "smsSecurity":
                  return (
                    <li key={control}>
                      Unlock via SMS/App?{" "}
                      {statusElement(rowData.smsSecurity || false)}
                    </li>
                  );
                case "odometerSecurity":
                  return (
                    <li key={control}>
                      Require odometer reading?
                      {statusElement(rowData.odometerSecurity || false)}
                    </li>
                  );
                case "requireJobID":
                  return (
                    <li key={control}>
                      Require Transaction Tag ID?
                      {statusElement(rowData.requireJobID || false)}
                    </li>
                  );
                case "enableSmsReceiptUpload":
                  return (
                    <li key={control}>
                      Enable receipt upload (SMS)?
                      {statusElement(rowData.enableSmsReceiptUpload || false)}
                    </li>
                  );
                case "fuelTypeSecurity":
                  return (
                    <li key={control}>
                      Flag wrong fuel type?
                      {statusElement(rowData.fuelTypeSecurity || false)}
                    </li>
                  );
                case "tankCapacitySecurity":
                  return (
                    <li key={control}>
                      Flag exceeding tank capacity?
                      {statusElement(rowData.tankCapacitySecurity || false)}
                    </li>
                  );
                case "suspiciousFuelingSecurity":
                  return (
                    <li key={control}>
                      Flag suspicious fueling?
                      {statusElement(
                        rowData.suspiciousFuelingSecurity || false,
                      )}
                    </li>
                  );
                case "flaggedMerchantCategories":
                  return (
                    <li key={control}>
                      Flagged Merchant Categories:
                      <ul className="pl-5 list-disc list-inside">
                        {(rowData.flaggedMerchantCategories || []).length >
                        0 ? (
                          rowData.flaggedMerchantCategories?.map((mc, idx) => (
                            <li key={idx}>
                              {CATEGORY_LABELS[mc]?.label || humanize(mc)}
                            </li>
                          ))
                        ) : (
                          <span>-</span>
                        )}
                      </ul>
                    </li>
                  );
                case "telematicsSecurity":
                  return (
                    <li key={control}>
                      Telematics Security:
                      <span className="ml-1 capitalize">
                        {rowData.telematicsSecurity?.toLowerCase() || "—"}
                      </span>
                    </li>
                  );
                default:
                  return null;
              }
            })}
          </ul>
        </section>
      )}

      {/* 4) SPENDING LIMITS */}
      {cardsFilters.spendingLimits.length > 0 && (
        <section>
          <h5 className="mb-1 font-semibold">Spending Limits</h5>
          <ul className="space-y-1 list-disc list-inside">
            {cardsFilters.spendingLimits.map((limit) => {
              // Find the matching spending record
              const matched = rowData.cardSpending?.find((sp) => {
                if (limit === "perTransactionSpendingLimit") {
                  return sp.interval === "per_authorization";
                }
                if (limit === "dailySpendingLimit") {
                  return sp.interval === "daily";
                }
                if (limit === "weeklySpendingLimit") {
                  return sp.interval === "weekly";
                }
                if (limit === "monthlySpendingLimit") {
                  return sp.interval === "monthly";
                }
                return false;
              });

              let label = "";
              switch (limit) {
                case "perTransactionSpendingLimit":
                  label = "Per Transaction Limit";
                  break;
                case "dailySpendingLimit":
                  label = "Daily Limit";
                  break;
                case "weeklySpendingLimit":
                  label = "Weekly Limit";
                  break;
                case "monthlySpendingLimit":
                  label = "Monthly Limit";
                  break;
                default:
                  break;
              }

              return (
                <li key={limit}>
                  {label}:{" "}
                  {matched?.amount
                    ? dollarFormatter(matched.amount / 100)
                    : "-"}
                </li>
              );
            })}
          </ul>
        </section>
      )}

      {/* 5) MERCHANT CATEGORIES */}
      {cardsFilters.merchantCategories.length > 0 && (
        <section>
          <h5 className="mb-1 font-semibold">Merchant Categories</h5>

          {/* Fuel Expenses */}
          {cardsFilters.merchantCategories.includes("fuelExpenses") && (
            <div className="mb-2">
              <strong>Fuel Expenses:</strong>
              <ul className="pl-4 list-disc list-inside">
                {rowData.merchants
                  ?.filter(
                    (m) =>
                      m.toggleOn &&
                      m.classification === ENUM_CLASSIFICATION.FUEL,
                  )
                  .map((m, idx) => (
                    <li key={idx}>
                      {CATEGORY_LABELS[m.name]?.label || humanize(m.name)}
                    </li>
                  ))}
              </ul>
            </div>
          )}

          {/* Vehicle Expenses */}
          {cardsFilters.merchantCategories.includes("vehicleExpenses") && (
            <div className="mb-2">
              <strong>Vehicle Expenses:</strong>
              <ul className="pl-4 list-disc list-inside">
                {rowData.merchants
                  ?.filter(
                    (m) =>
                      m.toggleOn &&
                      m.classification === ENUM_CLASSIFICATION.VEHICLE,
                  )
                  .map((m, idx) => (
                    <li key={idx}>
                      {CATEGORY_LABELS[m.name]?.label || humanize(m.name)}
                    </li>
                  ))}
              </ul>
            </div>
          )}

          {/* Business Expenses */}
          {cardsFilters.merchantCategories.includes("businessExpenses") && (
            <div className="mb-2">
              <strong>Business Expenses:</strong>
              <ul className="pl-4 list-disc list-inside">
                {rowData.merchants
                  ?.filter(
                    (m) =>
                      m.toggleOn &&
                      m.classification === ENUM_CLASSIFICATION.BUSINESS,
                  )
                  .map((m, idx) => (
                    <li key={idx}>
                      {CATEGORY_LABELS[m.name]?.label || humanize(m.name)}
                    </li>
                  ))}
              </ul>
            </div>
          )}

          {/* Additional Merchants */}
          {cardsFilters.merchantCategories.includes("additionalMerchants") && (
            <div className="mb-2">
              <strong>Additional Merchants:</strong>
              <ul className="pl-4 list-disc list-inside">
                {rowData.merchants
                  ?.filter(
                    (m) =>
                      m.toggleOn &&
                      m.classification === ENUM_CLASSIFICATION.OTHER,
                  )
                  .map((m, idx) => (
                    <li key={idx}>
                      {CATEGORY_LABELS[m.name]?.label || humanize(m.name)}
                    </li>
                  ))}
              </ul>
            </div>
          )}
        </section>
      )}
    </div>
  );
};
