/** @format */

import { useApolloClient, useLazyQuery, useMutation } from "@apollo/client";
import { DevicePhoneMobileIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { ENUM_COLOR_SCHEME, ENUM_USER_ACCOUNT_STATUS } from "@prisma/client";
import {
  READ_CURRENT_USER_AUTH,
  READ_CURRENT_USER_BASIC,
  SEND_DEVICE_VERIFICATION_SMS_FOR_CURRENT_USER,
  VERIFY_DEVICE_FOR_CURRENT_USER,
} from "@roadflex/graphql";
import {
  useReadCurrentUserAuth,
  useReadCurrentUserBasicInformation,
} from "@roadflex/react-hooks";
import { usePlatformDetect } from "@roadflex/react-hooks-www";
import { UserAuthType } from "@roadflex/types";
import {
  checkIfUserIsInImpersonatorMode,
  deleteCookie,
  getCookie,
  setCookie,
} from "@roadflex/web-lib";
import classNames from "classnames";
import { useFormik } from "formik";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { Button, ButtonSize, ButtonVariant } from "../../buttons";
import { OTPModal } from "../../modals";
import { Toast } from "../../toast-message/toast";
import { fuelcardRoutes } from "../fuelcardRoutes";
import AppHeader from "../headers/app-header/app-header";
import { OnboardingLayout } from "../onboarding-layout/onboarding-layout";
import SideBar from "../sidebars/sidebar";

export function FuelCardLayout({
  children,
}: {
  children: JSX.Element;
}): JSX.Element {
  const router = useRouter();
  const client = useApolloClient();
  const { isMobile } = usePlatformDetect();
  const [
    noOfFailedDeviceVerificationAttempts,
    setNoOfFailedDeviceVerificationAttempts,
  ] = useState<number>(0);
  const { readCurrentUserAuth, refetch: readCurrentUserAuthRefetch } =
    useReadCurrentUserAuth();
  const { readCurrentUserBasic, refetch: readCurrentUserBasicRefetch } =
    useReadCurrentUserBasicInformation();
  const [showDeviceVerificationModal, setShowDeviceVerificationModal] =
    useState<boolean>(false);
  const [otpSubmitting, setOtpSubmitting] = useState<boolean>(false);
  const [open, setopen] = useState(false);
  const toggle = () => setopen((o) => !o);
  const curr = fuelcardRoutes.find((f) => f.route === router?.route);
  const formikStateAndHelpers = useFormik({
    initialValues: { otp: "" },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
  });

  const handleLogout = async () => {
    deleteCookie("token");
    deleteCookie("firstLogin");
    deleteCookie("deviceToken");
    await client.clearStore();
    router.push("/");
  };

  const [sendDeviceVerificationSmsForCurrentUserFn] = useLazyQuery(
    SEND_DEVICE_VERIFICATION_SMS_FOR_CURRENT_USER,
    {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        setShowDeviceVerificationModal(true);
      },
      onError: (err) => {
        Toast({ type: "error", message: err.message });
      },
      notifyOnNetworkStatusChange: true,
    },
  );

  const [verifyDeviceForCurrentUserFn] = useMutation(
    VERIFY_DEVICE_FOR_CURRENT_USER,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      onCompleted: async (data) => {
        formikStateAndHelpers.resetForm();
        setCookie(
          `deviceToken`,
          data?.verifyDeviceForCurrentUser?.deviceToken,
          45,
        );
        setOtpSubmitting(false);
        //setShowDeviceVerificationModal(false);
        client
          .refetchQueries({
            include: [READ_CURRENT_USER_AUTH, READ_CURRENT_USER_BASIC],
          })
          .then(() => {
            setShowDeviceVerificationModal(false);
          })
          .catch(() => {
            setShowDeviceVerificationModal(false);
          });
      },
      onError: async (error) => {
        formikStateAndHelpers.resetForm();
        Toast({ type: "error", message: "Device verification failed" });
        setOtpSubmitting(false);
        const totalFailedAttempts = noOfFailedDeviceVerificationAttempts + 1;
        setNoOfFailedDeviceVerificationAttempts(totalFailedAttempts);
        if (totalFailedAttempts >= 3) {
          Toast({
            type: "error",
            message: "Too many failed attempts for device verification",
          });
          deleteCookie("impersonatorToken");
          handleLogout();
        }
        //setShowDeviceVerificationModal(false);
      },
    },
  );

  const onResendCode = () => {
    sendDeviceVerificationSmsForCurrentUserFn();
  };

  const onOtpSubmit = (value: { otp: string }) => {
    setOtpSubmitting(true);
    verifyDeviceForCurrentUserFn({
      variables: {
        data: {
          otp: value.otp,
        },
      },
    });
  };

  const isAllowedRouteForDeviceVerification = () => {
    const currentRoute = fuelcardRoutes.find((f) => f.route === router?.route);
    if (currentRoute) {
      return currentRoute.authenticated;
    }
    return false;
  };

  useEffect(() => {
    (async () => {
      const cookie = getCookie("token") || getCookie("impersonatorToken");
      if (cookie) {
        const readCurrentUserAuthData = client.readQuery({
          query: READ_CURRENT_USER_AUTH,
        });
        if (
          !(
            readCurrentUserAuthData &&
            readCurrentUserAuthData.readCurrentUserAuth
          )
        ) {
          await client.refetchQueries({
            include: [READ_CURRENT_USER_AUTH],
          });
        }

        const readCurrentUserBasicData = client.readQuery({
          query: READ_CURRENT_USER_BASIC,
        });
        if (
          !(
            readCurrentUserBasicData &&
            readCurrentUserBasicData.readCurrentUserBasic
          )
        ) {
          await client.refetchQueries({
            include: [READ_CURRENT_USER_BASIC],
          });
        }
        setopen(false);
      }

      return async () => {
        await client.clearStore();
      };
    })();
  }, [router?.asPath, readCurrentUserAuthRefetch, readCurrentUserBasicRefetch]);

  useEffect(() => {
    // console.log(isAllowedRouteForDeviceVerification());
    // console.log(checkIfUserIsInImpersonatorMode());
    // console.log(readCurrentUserAuth);
    // console.log(getCookie("deviceToken"));
    if (
      readCurrentUserAuth &&
      readCurrentUserBasic &&
      isAllowedRouteForDeviceVerification() &&
      !checkIfUserIsInImpersonatorMode()
    ) {
      if (!readCurrentUserBasic?.isPhoneVerified || !getCookie("deviceToken")) {
        sendDeviceVerificationSmsForCurrentUserFn();
      } else if (
        !readCurrentUserAuth?.isDeviceAuthorized ||
        !readCurrentUserAuth?.isLoggedIn
      ) {
        handleLogout();
      }
    }
  }, [router?.asPath, readCurrentUserAuth, readCurrentUserBasic]);

  useEffect(() => {
    if (readCurrentUserAuth && readCurrentUserBasic) {
      (async () => {
        if (
          // !readCurrentUserBasic?.isPhoneVerified || // Commenting this as the user is being logged out instead of encountering the OTP modal.
          (readCurrentUserAuth.fuelCard.status !==
            ENUM_USER_ACCOUNT_STATUS.ONBOARD &&
            !readCurrentUserAuth.isSSNSet) ||
          !(getCookie("token") || getCookie("impersonatorToken"))
        ) {
          await client.clearStore();
          deleteCookie("impersonatorToken");
          deleteCookie("token");
          deleteCookie("firstLogin");
          router.push("/");
          return;
        }

        // console.log(isAllowedRouteForDeviceVerification());
        // console.log(checkIfUserIsInImpersonatorMode());
        // console.log(readCurrentUserAuth);
        // console.log(getCookie("deviceToken"));

        if (
          isAllowedRouteForDeviceVerification() &&
          !checkIfUserIsInImpersonatorMode() &&
          (!readCurrentUserAuth?.isDeviceAuthorized ||
            !readCurrentUserBasic?.isPhoneVerified ||
            !getCookie("deviceToken"))
        ) {
          sendDeviceVerificationSmsForCurrentUserFn();
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readCurrentUserAuth, readCurrentUserBasic]);

  if (curr?.dontShow) {
    return children;
  }
  const byPassOtp = readCurrentUserAuth?.bypassOtpDuringLogIn || false;
  if (curr?.authenticated && readCurrentUserAuth) {
    return (
      <>
        <div className="hidden md:flex md:w-72 md:flex-col md:fixed md:inset-y-0">
          <SideBar
            show={open}
            toggle={toggle}
            readCurrentUserAuth={readCurrentUserAuth}
            readAccountDisplaySetting={
              readCurrentUserAuth?.accountDisplaySetting
            }
          />
        </div>
        <main
          className={classNames(
            readCurrentUserAuth?.accountDisplaySetting?.colorScheme ===
              ENUM_COLOR_SCHEME.DEFAULT
              ? "bg-orange-100"
              : readCurrentUserAuth?.accountDisplaySetting?.colorScheme ===
                ENUM_COLOR_SCHEME.COOL_AND_FRESH
              ? "bg-coolFresh1 bg-opacity-80"
              : readCurrentUserAuth?.accountDisplaySetting?.colorScheme ===
                ENUM_COLOR_SCHEME.NATURAL_AND_EARTHY
              ? "bg-earthy1 bg-opacity-60"
              : readCurrentUserAuth?.accountDisplaySetting?.colorScheme ===
                ENUM_COLOR_SCHEME.FUTURISTIC
              ? "bg-futuristic1 bg-opacity-80"
              : "bg-orange-100",
            "flex flex-col flex-1 h-full md:pl-72",
          )}
          style={{ minHeight: "100vh" }}
        >
          <AppHeader
            open={open}
            toggle={toggle}
            readCurrentUserAuth={readCurrentUserAuth}
          />
          <div className="flex flex-col flex-1 h-full mt-5 md:mt-0">
            {isMobile() && !curr.isMobileResponsive ? (
              <div className="flex flex-col items-center justify-center flex-1 h-full px-4 -mt-10">
                <div className="flex items-center justify-center ">
                  <div className="max-w-md p-8 text-center bg-white border border-gray-300 rounded-lg shadow-lg">
                    <div className="relative flex justify-center mx-auto mb-4">
                      <DevicePhoneMobileIcon className="flex w-10 h-10"></DevicePhoneMobileIcon>
                      <XMarkIcon className="absolute w-5 h-5 top-2"></XMarkIcon>
                    </div>
                    <h2 className="mb-4 text-2xl font-semibold text-gray-800">
                      Mobile Access Restricted
                    </h2>
                    <p className="mb-4 text-gray-600">
                      This page cannot be accessed on a mobile screen. Please
                      log into your account on the desktop to access this page.
                    </p>
                    <Button
                      variant={ButtonVariant.AppOrange}
                      onClick={() => {
                        router.push("/dashboard");
                      }}
                      className="mx-auto"
                      size={ButtonSize.Normal}
                    >
                      Back to Dashboard
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              children
            )}
          </div>
          {showDeviceVerificationModal && !byPassOtp && (
            <OTPModal
              {...{
                ...formikStateAndHelpers,
              }}
              show={showDeviceVerificationModal}
              setShow={setShowDeviceVerificationModal}
              onOtpSubmit={onOtpSubmit}
              otpSubmitting={otpSubmitting}
              resendCode={onResendCode}
              heading={`Verify your Device`}
              hideCrossButton={true}
              message={"Enter the 6-digit verification code sent via SMS"}
            />
          )}
        </main>
      </>
    );
  }
  if (!curr?.authenticated && !curr?.dontShow) {
    return (
      <OnboardingLayout
        readCurrentUserAuth={readCurrentUserAuth as UserAuthType}
      >
        {children}
      </OnboardingLayout>
    );
  }
  return children;
}
