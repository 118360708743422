/** @format */
import { usePlatformDetect } from "@roadflex/react-hooks";
import { DriverAnalysisPerformanceType } from "@roadflex/types";
import { dollarFormatter } from "@roadflex/web-lib";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Column } from "primereact/column";
import { DataTable, DataTableRowToggleParams } from "primereact/datatable";
import { Skeleton } from "primereact/skeleton";
import { useState } from "react";
import { newPaginationTemplate } from "../../../datatable/pagination-templates/new-pagination-template";
import { Select } from "../../../dropdowns";
import { InlineLoader } from "../../../loader";

type DriverPerformanceUIProps = {
  loading: boolean;
  topN: DriverAnalysisPerformanceType[];
  bottomN: DriverAnalysisPerformanceType[];
  number: number;
  noSpend: DriverAnalysisPerformanceType[];
  decline: DriverAnalysisPerformanceType[];
  timePeriod: number;
  setTimePeriod: (timePeriod: number) => void;
};

const NoDataToShowMessage = () => {
  return (
    <div className="absolute inset-0 flex items-center justify-center w-full h-full bg-white bg-opacity-70">
      <div className="p-6 text-base font-semibold bg-white border-2 rounded-md border-brown-500 text-brown-500 md:text-lg">
        No Data to Show
      </div>
    </div>
  );
};

const DriverPerformanceUI = ({
  loading,
  topN,
  bottomN,
  number,
  decline,
  noSpend,
  timePeriod,
  setTimePeriod,
}: DriverPerformanceUIProps) => {
  const { isMobile } = usePlatformDetect();
  const data: {
    name: string;
    y: number;
  }[] = decline.map((key) => ({
    name: key.driverName,
    y: key.declineCount,
  }));
  const [expandedRows, setExpandedRows] = useState<
    DriverAnalysisPerformanceType[]
  >([]);
  const [expandedRows2, setExpandedRows2] = useState<
    DriverAnalysisPerformanceType[]
  >([]);

  const options: Highcharts.Options = {
    chart: {
      type: "pie",
    },
    title: {
      text: ``,
      style: {
        textAlign: "left",
        color: "#893f2b",
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: "600",
      },
      align: "left",
    },
    tooltip: {
      formatter: function () {
        return `Driver Name: <b>${this.key}</b><br/><b>${this.y}</b>`;
      },
      shared: true,
    },
    credits: {
      enabled: false,
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    colors: [
      "#823920",
      "#ff5100",
      "#de8a26",
      "#6b9848",
      "#2670de",
      "#9f9f9f",
      "#893f2b",
      "#3a3939s",
    ],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 300,
          },
          chartOptions: {
            legend: {
              align: "center",
              verticalAlign: "bottom",
              layout: "horizontal",
            },
            plotOptions: {
              pie: {
                dataLabels: {
                  enabled: false,
                },
              },
            },
          },
        },
      ],
    },

    series: [
      {
        name: "Share",
        colorByPoint: true,
        data: data,
        type: "pie" as never,
      },
    ],
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b>: {point.percentage:.1f} %",
        },
        showInLegend: true,
        innerSize: "60%",
        point: {
          events: {
            legendItemClick: function () {
              return false; // <== returning false will cancel the default action
            },
          },
        },
      },
    },
  };

  // Expansion template
  const rowExpansionTemplate = (rowData: DriverAnalysisPerformanceType) => {
    return (
      <div className="max-w-sm p-4 mx-auto bg-white border border-gray-300 rounded-md shadow-lg">
        <div className="flex flex-col gap-2 text-sm text-gray-600">
          <div>Driver Name: {rowData.driverName}</div>
          <div>Card Number: {rowData.cardNumber.join(", ")}</div>
          <div>Vehicle: {rowData.vehicleName.join(", ")}</div>
          <div>Total Spend: {dollarFormatter(rowData.totalSpend / 100)}</div>
          <div>
            Discount Amount: {dollarFormatter(rowData.discountAmount / 100)}
          </div>
          <div>Flag Count: {rowData.flagCount}</div>
          <div>Decline Count: {rowData.declineCount}</div>
          <div>Fuel (gal): {Math.round(rowData.fuelQuantity * 100) / 100}</div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex-col flex flex-1 my-4 mt-0 overflow-x-hidden md:pb-4 h-full md:min-h-[calc(100vh-96px)]">
      <div className="col-span-2 mb-4">
        <div className="flex flex-col items-center justify-start gap-10 md:flex-row">
          <div className="w-full md:w-fit">
            <div className="flex flex-row items-center">
              <label className="pr-2 font-semibold" htmlFor="timePeriod">
                Time Period
              </label>
              <Select
                id="timePeriod"
                value={timePeriod}
                options={[
                  { value: 30, label: "30 days" },
                  { value: 60, label: "60 days" },
                  { value: 90, label: "90 days" },
                ]}
                optionLabel="label"
                optionValue="value"
                onChange={(e) => {
                  setTimePeriod(e.value);
                }}
                placeholder="Select days"
                className="w-28"
                itemTemplate={(option) => {
                  return (
                    <span className={`mode-${option.value}`}>
                      {option.label}
                    </span>
                  );
                }}
              ></Select>
              {loading && <InlineLoader />}
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <div className="inline-block min-w-full align-middle bg-white border border-gray-200 rounded-md shadow sm:py-6 sm:px-6">
          <Skeleton width="100%" height="5rem" className="mb-2"></Skeleton>
          <Skeleton width="100%" height="5rem" className="mb-2"></Skeleton>
          <Skeleton width="100%" height="5rem" className="mb-2"></Skeleton>
          <Skeleton width="100%" height="5rem"></Skeleton>
        </div>
      ) : (
        <div className="relative p-4 overflow-hidden bg-white border border-gray-200 rounded-md shadow sm:pt-6 sm:px-6">
          <label className="py-2 text-base font-semibold text-brown-500 md:text-lg">
            Declined Transactions By Driver (Past {timePeriod} days, Max 20
            Drivers)
          </label>
          <div className="relative">
            <HighchartsReact highcharts={Highcharts} options={options} />
            {decline.length > 0 ? null : <NoDataToShowMessage />}
          </div>
        </div>
      )}
      <div className="mt-8 bg-white rounded-md shadow">
        <div className="p-4 font-medium bg-white md:p-8 step-5">
          <div className="mb-4 font-semibold text-brown-500 md:text-lg">
            Top {number} drivers (spend past {timePeriod} days)
          </div>
          <div className="flex flex-col h-full mt-4">
            <DataTable
              size="small"
              loading={loading}
              stripedRows
              value={topN}
              pageLinkSize={isMobile() ? 2 : 3}
              paginator
              rows={10}
              dataKey="driverName"
              style={{ fontFamily: "Inter" }}
              responsiveLayout={isMobile() ? "scroll" : "scroll"}
              sortField="totalSpend"
              sortOrder={-1}
              paginatorTemplate={newPaginationTemplate}
              emptyMessage="No available records"
              className="!bg-white w-auto min-w-full custom-table !text-brown-500 !border-brown-500"
              // Expansion config
              rowExpansionTemplate={rowExpansionTemplate}
              expandedRows={expandedRows}
              onRowToggle={(e: DataTableRowToggleParams) =>
                setExpandedRows(e.data as DriverAnalysisPerformanceType[])
              }
            >
              <Column
                field="driverName"
                header="Driver Name"
                className="text-sm 5xl:text-base"
                bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                headerClassName="!bg-white !text-brown-500 !border-brown-500"
              />

              {!isMobile() && (
                <Column
                  field="cardNumber"
                  header="Card Number"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  body={(r) => r.cardNumber.map(String).join(", ")}
                />
              )}

              {!isMobile() && (
                <Column
                  field="vehicleName"
                  header="Vehicle Name"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  body={(r) => r.vehicleName.map(String).join(", ")}
                />
              )}

              {!isMobile() && (
                <Column
                  field="totalSpend"
                  header="Total Spend"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  body={(r) => dollarFormatter(r.totalSpend / 100)}
                />
              )}

              {!isMobile() && (
                <Column
                  field="discountAmount"
                  header="Discount Amount"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  body={(r) => dollarFormatter(r.discountAmount / 100)}
                />
              )}

              {!isMobile() && (
                <Column
                  field="flagCount"
                  header="Flag Count"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                />
              )}

              {!isMobile() && (
                <Column
                  field="declineCount"
                  header="Decline Count"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                />
              )}

              {!isMobile() && (
                <Column
                  field="fuelQuantity"
                  header="Fuel (gal)"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  body={(r) => Math.round(r.fuelQuantity * 100) / 100}
                />
              )}
              {isMobile() && (
                <Column
                  expander
                  style={{ width: "3em" }}
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                />
              )}
            </DataTable>
          </div>
        </div>
      </div>
      <div className="mt-8 bg-white rounded-md shadow">
        <div className="p-4 font-medium bg-white md:p-8 step-5">
          <div className="mb-4 font-semibold text-brown-500 md:text-lg">
            Bottom {number} drivers (spend past {timePeriod} days, excluding
            drivers with no spend)
          </div>
          <div className="mb-4 text-xs font-normal">
            <div className="font-semibold">Drivers with no spend:</div>
            {noSpend.map((x) => x.driverName.trim()).join(", ")}
          </div>
          <div className="flex flex-col h-full mt-4">
            <DataTable
              size="small"
              loading={loading}
              stripedRows
              value={bottomN}
              pageLinkSize={isMobile() ? 2 : 3} // Similar to top table; adjust if you like
              paginator
              rows={10}
              dataKey="driverName"
              style={{ fontFamily: "Inter" }}
              responsiveLayout={isMobile() ? "scroll" : "scroll"} // same as the top table
              sortField="totalSpend"
              sortOrder={1} // ascending for bottomN
              paginatorTemplate={newPaginationTemplate}
              emptyMessage="No available records"
              className="!bg-white w-auto min-w-full custom-table !text-brown-500 !border-brown-500"
              rowExpansionTemplate={rowExpansionTemplate}
              expandedRows={expandedRows2}
              onRowToggle={(e: DataTableRowToggleParams) =>
                setExpandedRows2(e.data as DriverAnalysisPerformanceType[])
              }
            >
              <Column
                field="driverName"
                header="Driver Name"
                className="text-sm 5xl:text-base"
                bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                headerClassName="!bg-white !text-brown-500 !border-brown-500"
              />

              {!isMobile() && (
                <Column
                  field="cardNumber"
                  header="Card Number"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  body={(r) => r.cardNumber.map(String).join(", ")}
                />
              )}

              {!isMobile() && (
                <Column
                  field="vehicleName"
                  header="Vehicle Name"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  body={(r) => r.vehicleName.map(String).join(", ")}
                />
              )}

              {!isMobile() && (
                <Column
                  field="totalSpend"
                  header="Total Spend"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  body={(r) => dollarFormatter(r.totalSpend / 100)}
                />
              )}

              {!isMobile() && (
                <Column
                  field="discountAmount"
                  header="Discount Amount"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  body={(r) => dollarFormatter(r.discountAmount / 100)}
                />
              )}

              {!isMobile() && (
                <Column
                  field="flagCount"
                  header="Flag Count"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                />
              )}

              {!isMobile() && (
                <Column
                  field="declineCount"
                  header="Decline Count"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                />
              )}

              {!isMobile() && (
                <Column
                  field="fuelQuantity"
                  header="Fuel (gal)"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  body={(r) => Math.round(r.fuelQuantity * 100) / 100}
                />
              )}

              {isMobile() && (
                <Column
                  expander
                  style={{ width: "3em" }}
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                />
              )}
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DriverPerformanceUI;
